import React, { useState, useContext, useRef } from 'react';
import BaseAuth, { HeaderWithSeparator } from '../Layouts/BaseAuth';
import ReCAPTCHA from "react-google-recaptcha";
import { HttpRequestContext } from '../Components/HttpRequestProvider';
import { CustomButton } from '../Components/Buttons';
import { TextInputWithIcon,DropdownWithIcon,PanelRadioSelect  } from '../Components/InputFields';
import { HorizontalSeparator } from '../Components/Separators';
import { InformationTextPanel } from '../Components/Panels';
import TemplateSelectionModal from './Modules/TemplateSelectionModal';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../Components/UserProvider';


export function OnboardingStart() {


    const { user } = useContext(UserContext);

    const [currentStep, setCurrentStep] = useState(1);
    const navigate = useNavigate(); // This should already be at the top of your OnboardingStart function

    // Step 1
    const [businessName, setBusinessName] = useState("");
    const [selectedIndustry, setSelectedIndustry] = useState("");
    const [hasWebsite, setHasWebsite] = useState(false);
    const [websiteUrl, setWebsiteUrl] = useState("");

    // Step 2
    const [offerings, setOfferings] = useState(['', '', '']);

    // Step 3 : Template selection
    const [showTemplateModal, setShowTemplateModal] = useState(false);
    const [selectedTemplate, setSelectedTemplate] = useState(null);

    // Step 4: Personal Details
    const [fullName, setFullName] = useState("");
    const [email, setEmail] = useState("");  // Note: You already have this, so no need to re-declare
    const [phoneNumber, setPhoneNumber] = useState("");
    const [password, setPassword] = useState("");  // Note: You already have this, so no need to re-declare
    const [confirmPassword, setConfirmPassword] = useState("");

    const { sendRequestWithToast } = useContext(HttpRequestContext);

    const processOnboarding = async () => {
        console.log("Attempting to log in...");
        const request = {
            email: email,
            password: password        
        };

        const response = await sendRequestWithToast({
            apiPath: "login",   
            request: request,
            type: "POST",
            successMessage: "Connection réussie",
            errorMessage: "Erreur lors de la connexion",
            successRedirectUrl: "/booking"
        });
        
        console.log(response);
    }

    const goToNextStep = async () => {
if (currentStep === 4) {
    // Prepare user data for request
    const userData = {
        email: email,
        password: password,  // Ensure password is valid and both passwords match
        full_name: fullName,
        phone_number: phoneNumber,
        // Add other necessary fields...
    };

    // Send request to backend to create a user
    const response = await sendRequestWithToast({
        apiPath: "user/register",  // or another appropriate route for user creation
        request: userData,
        type: "POST",
        successMessage: "Compte créé avec succès",
        errorMessage: "Erreur lors de la création du compte",
    });

    console.log(response);
    
    if (response && response.email) {
        navigate('/');
    }
    
}

        if (currentStep === 3) {
            try {
                // Send the data to the backend when step 3 is completed
                const response = await sendRequestWithToast({
                    apiPath: "/user/onboarding",
                    request: {
                        businessName,
                        selectedIndustry,
                        hasWebsite,
                        websiteUrl,
                        offerings,
                        selectedTemplate
                    },
                    type: "POST",
                    successMessage: "Onboarding data saved successfully",
                    errorMessage: "Error saving onboarding data",
                });
    
                // Check if user exists
                if (user) {
                    navigate("/");  // Redirect to root if user exists
                } else {
                    setCurrentStep(currentStep + 1);  // Proceed to step 4 if user is null
                }
            } catch (error) {
                console.error("Error during onboarding:", error);
            }
        } else if (currentStep < 4) {
            setCurrentStep(currentStep + 1);
        }
    };
    
    
    const goToPreviousStep = () => {
        if (currentStep > 1) {
            setCurrentStep(currentStep - 1);
        }
    }
    

    
    return (

        <BaseAuth>
            <div className="flex-grow mb-5">
                <div className="text-center mb-7">
                    <a href="/"><img src="/assets/images/logo2.svg" alt="Logo" className="h-12 float-left" /></a>
                    <h1 className="font-bold text-2xl sm:text-3xl text-gray-900">Démarrage Rapide</h1>
                    <div className="mt-1">
                        <HorizontalSeparator />
                    </div>
                </div>
                {currentStep === 1 && (
                    <BusinessDetailsStep 
                        businessName={businessName} 
                        setBusinessName={setBusinessName}
                        selectedIndustry={selectedIndustry}
                        setSelectedIndustry={setSelectedIndustry}
                        hasWebsite={hasWebsite}
                        setHasWebsite={setHasWebsite}
                        websiteUrl={websiteUrl}
                        setWebsiteUrl={setWebsiteUrl}
                        />
                    )}

                {currentStep === 2 && (
                    <OfferingStep offerings={offerings} setOfferings={setOfferings} />
                    )}

                {currentStep === 3 && (
                    <TemplateSelectionStep 
                        showTemplateModal={showTemplateModal} 
                        setShowTemplateModal={setShowTemplateModal}
                        selectedTemplate={selectedTemplate}
                        setSelectedTemplate={setSelectedTemplate}
                        selectedIndustry={selectedIndustry}
                        />
                    )}

                {currentStep === 4 && (
                    <PersonalDetailsStep 
                        fullName={fullName} 
                        setFullName={setFullName}
                        email={email} 
                        setEmail={setEmail}
                        phoneNumber={phoneNumber} 
                        setPhoneNumber={setPhoneNumber}
                        password={password} 
                        setPassword={setPassword}
                        confirmPassword={confirmPassword} 
                        setConfirmPassword={setConfirmPassword}
                    />
                )}

            </div>
            <div className="flex justify-between mt-8">
                <div className="flex-1 mr-2"> 
                    {currentStep === 1 ? (
                        <CustomButton
                            onClick={() => navigate('/login')}
                            label="Se connecter"
                            styleClass="btn-secondary" 
                            blockClass="block w-full" 
                        />
                    ) : (
                        <CustomButton
                            onClick={goToPreviousStep}
                            label="Précédent"
                            styleClass="btn-secondary" 
                            blockClass="block w-full" 
                        />
                    )}
                </div>
                <div className="flex-1 ml-2">
                    <CustomButton
                        onClick={goToNextStep}
                        label={currentStep === 4 ? "Terminer" : "Suivant"}
                        styleClass="btn-primary" 
                        blockClass="block w-full" 
                    />
                </div>

            </div>


        </BaseAuth>
    );
}


function BusinessDetailsStep({ businessName, setBusinessName, selectedIndustry, setSelectedIndustry, hasWebsite, setHasWebsite,websiteUrl,setWebsiteUrl }) {
    return (
        <div className="space-y-5 onboarding-page">
            <InformationTextPanel 
                text ="Pour vous offrir une expérience sur mesure, nous avons besoin de connaître le nom et l'industrie de votre entreprise."
            />
            <TextInputWithIcon
                id="onboarding-business-name"
                type="text"
                placeholder="Nom d'entreprise"
                label="Nom de votre entreprise"
                iconName="account-outline"
                name="business-name"
                value={businessName}
                onChange={(e) => setBusinessName(e.target.value)}
            />

            <DropdownWithIcon
                id="industry"
                label="Industrie"
                iconName="factory"
                value={selectedIndustry}
                onChange={(e) => setSelectedIndustry(e.target.value)}
                options={[
                    { value: 'Agriculture', label: 'Agriculture' },
                    { value: 'Alimentation & Boissons', label: 'Alimentation & Boissons' },
                    { value: 'Art & Culture', label: 'Art & Culture' },
                    { value: 'Automobile', label: 'Automobile' },
                    { value: 'Commerce de détail', label: 'Commerce de détail' },
                    { value: 'Construction', label: 'Construction' },
                    { value: 'Digital Marketing', label: 'Digital Marketing' },
                    { value: 'E-commerce', label: 'E-commerce' },
                    { value: 'Éducation', label: 'Éducation' },
                    { value: 'Énergie', label: 'Énergie' },
                    { value: 'Finance', label: 'Finance' },
                    { value: 'Gaming & Esports', label: 'Gaming & Esports' },
                    { value: 'Immobilier', label: 'Immobilier' },
                    { value: 'Loisirs', label: 'Loisirs' },
                    { value: 'Mode & Beauté', label: 'Mode & Beauté' },
                    { value: 'Pharmaceutique', label: 'Pharmaceutique' },
                    { value: 'SaaS (Software as a Service)', label: 'SaaS (Software as a Service)' },
                    { value: 'Santé', label: 'Santé' },
                    { value: 'Technologie', label: 'Technologie' },
                    { value: 'Télécommunications', label: 'Télécommunications' },
                    { value: 'Tourisme', label: 'Tourisme' },
                    { value: 'Transport', label: 'Transport' },
                    { value: 'Web Development', label: 'Web Development' },
                ]}
            />

            <HasWebsiteField 
                hasWebsite={hasWebsite} 
                setHasWebsite={setHasWebsite} 
                websiteUrl={websiteUrl}
                setWebsiteUrl={setWebsiteUrl}
            />


        </div>
    );
}


function OfferingStep({offerings, setOfferings}) {
    return (
        <div className="space-y-5 onboarding-page">
            
            <InformationTextPanel 
                text="En comprenant mieux ce que vous vendez, nous pourrons mieux structurer votre site web et vos campagnes de marketing. Cela permet de garantir que les solutions que nous proposons correspondent parfaitement à vos produits ou services."
            />
            {Array.from({ length: 3 }).map((_, index) => (
                <TextInputWithIcon
                    key={index}
                    id={`offering-${index + 1}`}
                    type="text"
                    iconName="tag"
                    placeholder={`Offre ${index + 1}`}
                    label={`Votre offre ${index + 1}`}
                    value={offerings[index] || ''}
                    onChange={(e) => {
                        const newOfferings = [...offerings];
                        newOfferings[index] = e.target.value;
                        setOfferings(newOfferings);
                    }}
                />
            ))}

            



        </div>
    );
}

function HasWebsiteField({ hasWebsite, setHasWebsite,websiteUrl,setWebsiteUrl }) {
    return (
        <div className="space-y-1">
            <PanelRadioSelect
                label="Nous créons votre site:"
                options={[
                    {
                        name: 'non',
                        value: 'non',
                        displayText: 'Créer un site web',
                        subLabel: 'Je n\'ai pas encore de site',
                    },
                    {
                        name: 'oui',
                        value: 'oui',
                        displayText: 'Améliorer mon site',
                        subLabel: 'J\'ai déjà un siteweb',
                    },
                ]}
                selectedValue={hasWebsite ? 'oui' : 'non'}
                onChange={value => setHasWebsite(value === 'oui')}
            />

            {hasWebsite && (
                <TextInputWithIcon
                    id="onboarding-business-name"
                    type="url"
                    placeholder="https://innovaplus.be"
                    label="URL de votre siteweb"
                    iconName="web"
                    name="website"
                    value={websiteUrl}
                    onChange={(e) => setWebsiteUrl(e.target.value)}
                />
            )}

        </div>
    );
}


function TemplateSelectionStep({
    showTemplateModal, 
    setShowTemplateModal, 
    selectedTemplate, 
    setSelectedTemplate,
    selectedIndustry
}) {
    return (
        <div>
            <InformationTextPanel 
                text="Un bon thème renforce votre marque, attire les visiteurs et optimise la navigation. Choisissez-en un adapté à votre secteur pour une expérience utilisateur idéale."
            />

            <CustomButton
                onClick={() => setShowTemplateModal(true)}
                label="Choisir mon thème"
                styleClass="bg-indigo-500 text-white btn-success text-lg px-5 py-3 rounded-lg mt-5" 
                blockClass="block w-full" 
            />

            {selectedTemplate && (
                <div className="mt-5 rounded-md overflow-hidden">
                    <div className="image-crop-container md:h-200 h-200">
                        <img src={`/dist/img/templates/${selectedTemplate.image_file}`} alt={selectedTemplate.title} className="cropped-image w-full object-cover" />
                    </div>
                    <div className="p-4 border-gray-300 bg-cyan-50">
                        <h2 className="text-md font-bold">{selectedTemplate.title}</h2>
                    </div>
                </div>
            )}

            {showTemplateModal && <TemplateSelectionModal 
                                        closeModal={() => setShowTemplateModal(false)} 
                                        selectedTemplate={selectedTemplate} 
                                        setSelectedTemplate={setSelectedTemplate} 
                                        initial_search={selectedIndustry}
                                    />}
        </div>
    );
}
function PersonalDetailsStep({ 
    fullName, setFullName,
    email, setEmail,
    phoneNumber, setPhoneNumber,
    password, setPassword,
    confirmPassword, setConfirmPassword
}) {
    return (
        <div className="space-y-5 onboarding-page">
            <InformationTextPanel 
                text="Nous utilisons ces informations pour créer votre compte qui vous permettra de gerer votre présence digitale. Vos données restent confidentielles."
                />
            <TextInputWithIcon
                id="full-name"
                type="name"
                name="name"
                placeholder="Nom complet"
                label="Nom complet"
                iconName="account"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
            />
            <TextInputWithIcon
                id="email"
                type="email"
                name="email"
                placeholder="Adresse e-mail"
                label="Adresse e-mail"
                iconName="email-outline"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
            />
            <TextInputWithIcon
                id="phone-number"
                type="phone"
                name="phone"
                placeholder="Numéro de téléphone"
                label="Numéro de téléphone"
                iconName="phone-outline"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
            />
            <TextInputWithIcon
                id="password"
                type="password"
                name="password"
                placeholder="Mot de passe"
                label="Mot de passe"
                iconName="lock-outline"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
            />
            <TextInputWithIcon
                id="confirm-password"
                name="confirm-password"
                type="password"
                placeholder="Confirmez le mot de passe"
                label="Confirmez le mot de passe"
                iconName="lock-outline"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
            />
        </div>
    );
}
