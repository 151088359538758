import React, { useState, useEffect, useContext } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { HttpRequestContext } from './HttpRequestProvider';
import Typography from '@mui/material/Typography';
const ItemTypes = {
  CARD: 'card'
};

function KanbanCard({ card, cardIndex, moveCard, columnIndex }) {
  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: { card, cardIndex, columnIndex },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging()
    })
  });

  return (
    <div ref={drag} className="bg-white p-4 m-2 border border-gray-300 rounded shadow" style={{ opacity: isDragging ? 0.5 : 1 }}>
      <Typography variant='paragraph' color='blue-gray' className="font-normal">
        {card.title}
      </Typography>
    </div>
  );
}

function KanbanColumn({ column, columnIndex, moveCard }) {
    const [, drop] = useDrop({
        accept: ItemTypes.CARD,
        hover: (draggedItem) => {
          if (draggedItem.columnIndex === columnIndex) {

          } else {
            moveCard(draggedItem.card, draggedItem.columnIndex, columnIndex);
            draggedItem.columnIndex = columnIndex;
          }
        }
      });
    
  
      const sortedCards = column.cards.sort((a, b) => a.title.localeCompare(b.title));

      return (
        <div ref={drop} className="flex flex-col w-72 p-4 bg-gray-100 m-2 rounded">
          <Typography variant='h5' color='blue-gray' className="font-bold mb-4">
            {column.title}
          </Typography>
          <div className="flex-1">
            {sortedCards.map((card, cardIndex) => (
              <KanbanCard key={cardIndex} card={card} columnIndex={columnIndex} moveCard={moveCard} />
            ))}
          </div>
        </div>
      );
}
  

function KanbanBoard({ apiEndpoint, kanbanColumnField, forcedColumns }) {
  const { sendRequest } = useContext(HttpRequestContext);
  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const data = await sendRequest({ apiPath: apiEndpoint, type: 'GET' });

        let columnMapping = {};
        if (forcedColumns) {
          // Initialize columns based on forcedColumns
          forcedColumns.forEach(columnTitle => {
            columnMapping[columnTitle] = { title: columnTitle, cards: [] };
          });
        }

        data.rows.forEach(row => {
          const columnTitle = forcedColumns ? 
                              (forcedColumns.includes(row[kanbanColumnField]) ? row[kanbanColumnField] : 'Non Défini') :
                              (row[kanbanColumnField] || 'Undefined');

          if (!columnMapping[columnTitle]) {
            columnMapping[columnTitle] = { title: columnTitle, cards: [] };
          }
          columnMapping[columnTitle].cards.push({ title: row.Nom || 'Titre par défaut', ...row });

        });

        setColumns(Object.values(columnMapping));
      } catch (err) {
        console.error('Error fetching Kanban data:', err);
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [apiEndpoint, kanbanColumnField, sendRequest, forcedColumns]);


  const moveCard = (card, fromColumnIndex, toColumnIndex) => {
    const updatedColumns = [...columns];
  
    updatedColumns[fromColumnIndex].cards = updatedColumns[fromColumnIndex].cards.filter((c) => c !== card);
    updatedColumns[toColumnIndex].cards.push(card);
  
    setColumns(updatedColumns);
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="flex overflow-x-auto">
        {columns.map((column, index) => (
          <KanbanColumn key={index} columnIndex={index} column={column} moveCard={moveCard} />
        ))}
      </div>
    </DndProvider>
  );
}

export default KanbanBoard;
