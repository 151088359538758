import React, { useState } from 'react';
import Dashboard from '../../Layouts/Dashboard';
import CreateCRMTypeForm from '../Modules/CreateCRMTypeForm';
import EditCRMTypeProperties from '../Modules/EditCRMTypeProperties';
import DefineCRMTypeChildren from '../Modules/DefineCRMTypeChildren'; // Import your new component
import { SortableTable } from '../../Components/Table';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Modal from '../../Components/Modal';


const ManageCRMTypes = () => {
    const [isPropertyModalOpen, setIsPropertyModalOpen] = useState(false);
    const [isChildModalOpen, setIsChildModalOpen] = useState(false); // New state for child modal
    const [currentCRMType, setCurrentCRMType] = useState(null);
    

    // Define columns for the CRM Types table
    const crmTypeColumns = {
        display_name: { name: 'Nom', Type: 'text', sortable: true },
        description: { name: 'Description', Type: 'text', sortable: true },
        type_name: { name: 'Type Interne', Type: 'text', sortable: true },
        edit: {
            Type: 'action',
            name: 'Propriétés',
            sortable: false,
            actionCallback: (row) => {
              setCurrentCRMType(row);
              setIsPropertyModalOpen(true);
            },
            tooltipContent: "Modifier"
          },
        manageChildren: { // New column for managing child CRM objects
            Type: 'action',
            name: 'Gérer les liens',
            sortable: false,
            actionCallback: (row) => {
              setCurrentCRMType(row);
              setIsChildModalOpen(true); // Open the child modal
            },
            tooltipContent: "Gérer les liens"
        }
    };

    return (
        <Dashboard dashboardTitle={'Manage CRM Types'}>
            <div className='p-4 w-full'>
                <CreateCRMTypeForm />

                <Card variant="level1">
                    <CardContent>
                        <Typography variant='h5' color='blue-gray' className='mb-2'>
                            Gestion des propriétés
                        </Typography>
                        <SortableTable
                            apiEndpoint="/crm/types/list"
                            Columns={crmTypeColumns}
                        />
                    </CardContent>
                </Card>

                {/* Existing Modal for EditCRMTypeProperties */}
                <Modal isOpen={isPropertyModalOpen} closeModal={() => setIsPropertyModalOpen(false)}>
                    {currentCRMType && (
                        <EditCRMTypeProperties 
                            typeName={currentCRMType.type_name} 
                            onClose={() => setIsPropertyModalOpen(false)} 
                        />
                    )}
                </Modal>

                {/* New Modal for DefineCRMTypeChildren */}
                <Modal isOpen={isChildModalOpen} closeModal={() => setIsChildModalOpen(false)}>
                    {currentCRMType && (
                        <DefineCRMTypeChildren 
                            typeName={currentCRMType.type_name} 
                            onClose={() => setIsChildModalOpen(false)} 
                        />
                    )}
                </Modal>
            </div>
        </Dashboard>
    );
};

export default ManageCRMTypes;
