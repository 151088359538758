// AdminUsersPage.js
import React, { useState } from 'react';
import { Box, Grid, Button } from '@mui/material';
import UserSearch from './UserSearch';
import UserCard from './UserCard';
import UserCRMData from './UserCRMData';
import { useNavigate } from 'react-router-dom';

const AdminUsersPage = () => {
    const [selectedUser, setSelectedUser] = useState(null);
    const navigate = useNavigate();

    const handleUserSelect = (user) => {
        setSelectedUser(user);
    };

    const handleCreateUserClick = () => {
        navigate('/admin/users/create');
    };

    return (
        <Box sx={{ p: 3 }}>
            <Button variant="contained" color="primary" onClick={handleCreateUserClick} sx={{ mb: 3 }}>
                Créer un nouvel utilisateur
            </Button>
            <UserSearch onSearch={handleUserSelect} />
            {selectedUser && (
                <Grid container spacing={3}>
                    <Grid item xs={12} md={4}>
                        <UserCard user={selectedUser} />
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <UserCRMData user={selectedUser} />
                    </Grid>
                </Grid>
            )}
        </Box>
    );
};

export default AdminUsersPage;
