import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';

const UserCard = ({ user }) => {
    if (!user) {
        return <Typography variant="h6">Aucun utilisateur sélectionné</Typography>;
    }

    return (
        <Card variant="outlined">
            <CardContent>
                <Typography variant="h5" gutterBottom>
                    {user.name}
                </Typography>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                    Email: {user.email}
                </Typography>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                    Téléphone: {user.phone_number || 'N/A'}
                </Typography>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                    Société: {user.business_name || 'N/A'}
                </Typography>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                    Site Web: {user.website_url || 'N/A'}
                </Typography>
            </CardContent>
        </Card>
    );
};

export default UserCard;
