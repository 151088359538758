import React, { useState, useEffect, useContext } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { UserContext } from '../UserProvider';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Link } from 'react-router-dom';
import { HttpRequestContext } from '../HttpRequestProvider';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';

const AdminNavbar = ({ toggleFullScreen, disableFullScreenButton }) => {
    const { logout } = useContext(UserContext);
    const { sendRequest } = useContext(HttpRequestContext);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [anchorEl, setAnchorEl] = useState(null);

    const navLinks = [
        !disableFullScreenButton && { onClick: toggleFullScreen, label: '', icon: <FullscreenIcon /> },
        { href: '/admin/dashboard', label: 'Tableau de bord admin' },
        { href: '/admin/categories', label: 'Catégories admin' },
        { href: '/', label: 'Retour à l\'application utilisateur' },
        { onClick: logout, label: 'Logout' }
    ].filter(Boolean); // Remove null items

    const toggleDrawer = (isOpen) => () => {
        setDrawerOpen(isOpen);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const renderMobileMenu = (
        <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
            <List>
                {navLinks.map((link, index) => 
                    link.children ? (
                        <ListItem button onClick={link.onClick} key={index + "-head"}>
                            <ListItemText primary={link.label} key={index + "-items"} />
                            <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleMenuClose}
                            >
                                {link.children.map((childLink) => (
                                    <MenuItem 
                                        key={childLink.href} 
                                        onClick={handleMenuClose}
                                    >
                                        <Link to={childLink.href}>
                                            {childLink.label}
                                        </Link>
                                    </MenuItem>
                                ))}
                            </Menu>
                        </ListItem>
                    ) : (
                        <ListItem button key={index} onClick={link.onClick} href={link.href}>
                            <ListItemText primary={link.label} />
                        </ListItem>
                    )
                )}
            </List>
        </Drawer>
    );

    return (
        <AppBar position="fixed" style={{ backgroundColor: '#ffffff', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', zIndex: theme.zIndex.drawer + 1 }}>
            <Toolbar>
                <IconButton edge="start" color="inherit" aria-label="home" href="/">
                    <img src="/assets/images/logo2.svg" alt="Company Logo" style={{ width: '30px' }} />
                    <h1 className="ml-3 font-bold text-xl" style={{ color: theme.palette.primary.main }}>Innovaplus Admin</h1>
                </IconButton>

                {isMobile ? (
                    <>
                        <div style={{ flexGrow: 1 }}></div>
                        <IconButton color="inherit" aria-label="menu" onClick={toggleDrawer(true)}>
                            <MenuIcon />
                        </IconButton>
                        {renderMobileMenu}
                    </>
                ) : (
                    <>
                        <div style={{ flexGrow: 1 }}></div>
                        {navLinks.map((link, index) => 
                            link.children ? (
                                <div key={link.label}>
                                    <Button color="inherit" onClick={link.onClick}>
                                        {link.label}
                                    </Button>
                                    <Menu
                                        anchorEl={anchorEl}
                                        open={Boolean(anchorEl)}
                                        onClose={handleMenuClose}
                                    >
                                        {link.children.map((childLink) => (
                                            <MenuItem 
                                                key={childLink.href} 
                                                onClick={handleMenuClose} 
                                            >
                                                <Link to={childLink.href}>
                                                    {childLink.label}
                                                </Link>
                                            </MenuItem>
                                        ))}
                                    </Menu>
                                </div>
                            ) : (
                                <Button key={index + "-button"} color="inherit" onClick={link.onClick} href={link.href}>
                                    {link.icon} {link.label}
                                </Button>
                            )
                        )}
                    </>
                )}
            </Toolbar>
        </AppBar>
    );
}

export default AdminNavbar;
