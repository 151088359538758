import React from 'react';

export function MDIIcon({ iconName, additionalClasses = "text-gray-400 text-lg" }) {
    return (
            <i className={`mdi mdi-${iconName} ${additionalClasses}`}></i>
    );
}


export function FAIcon({ iconName, additionalClasses = "fill-current text-gray-900 group-hover:text-cyan-300",  fas = false }) {
    const iconStyle = fas ? 'fas' : 'fa';
    return (
        <i className={`${iconStyle} fa-${iconName} ${additionalClasses}`}></i>
    );
}

export function Icon({ iconName, additionalClasses = "", type = "mdi", fas = false }) {
    if(type === "mdi") {
        return <MDIIcon iconName={iconName} additionalClasses={additionalClasses} />
    } else if(type === "fa") {
        return <FAIcon iconName={iconName} additionalClasses={additionalClasses} fas={fas} />
    } else {
        return null; // or return a default icon, or an error icon, etc.
    }
}