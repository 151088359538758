// withReactFlowProvider.js
import React from 'react';
import { ReactFlowProvider } from 'reactflow';

const withReactFlowProvider = (Component) => (props) => {
  return (
    <ReactFlowProvider>
      <Component {...props} />
    </ReactFlowProvider>
  );
};

export default withReactFlowProvider;
