import React, { useState, createContext, useEffect, useContext } from 'react';
import { HttpRequestContext } from '../Components/HttpRequestProvider';
import { useNavigate, useLocation } from 'react-router-dom';

export const UserContext = createContext(null);

export function UserProvider({ children }) {
    const [user, setUser] = useState(null);
    const [isLoading, setIsLoading] = useState(true); 
    const { sendRequest } = useContext(HttpRequestContext);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const fetchUserInformation = async () => {
            if (['/login', '/onboarding', '/form'].includes(location.pathname)) {
                setIsLoading(false); 
                return;
            }
            try {
                const userData = await sendRequest({
                    apiPath: '/user',
                    type: 'GET',
                });
                if (userData) {
                    setUser(userData);
                } else {
                    throw new Error("No user data returned from API.");
                }
            } catch (error) {
                console.error("Error fetching user data:", error); 
                navigate('/login');
            } finally {
                setIsLoading(false); 
            }
        };

        fetchUserInformation();
    }, [sendRequest, navigate, location.pathname]); 

    const logout = async () => {
        console.log("Logging out...");
        try {
            await sendRequest({
                apiPath: '/logout',
                type: 'POST',
            });
            setUser(null);
            navigate('/login');
        } catch (error) {
            console.error("Logout failed:", error);
        }
    };

    return (
        <UserContext.Provider value={{ user, setUser, logout, isLoading }}>
            {children}
        </UserContext.Provider>
    );
}

export default UserProvider;
