import React, { useState, useContext, useEffect } from 'react';
import { DropdownWithIcon, TextInputWithIcon } from '../../Components/InputFields';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Box from '@mui/material/Box';
import LineItemTable from './LineItemTable';
import { HttpRequestContext } from '../../Components/HttpRequestProvider';

const CreditNoteForm = ({ billingprofiles, customers, onCreditNoteCreated, selectedBillingProfileId }) => { 
    const { sendRequestWithToast, sendRequest } = useContext(HttpRequestContext);
    const [noteDeCreditData, setNoteDeCreditData] = useState({
        billingprofile: selectedBillingProfileId,
        customer: '',
        invoice: '',
        ncDate: '',
        lineItems: [],
        invoiceOptions: [],
        ncStatus: 'Brouillon',
        motif: '', // Ajout du champ motif
    });

    useEffect(() => {
        setNoteDeCreditData((prevData) => ({
            ...prevData,
            billingprofile: selectedBillingProfileId,
        }));
    }, [selectedBillingProfileId]);

    const [isFormExpanded, setIsFormExpanded] = useState(false);

    const handleLineItemChange = (lineItems) => {
        setNoteDeCreditData(prevData => ({
            ...prevData,
            lineItems
        }));
    };

    const fetchInvoices = async () => {
        const response = await sendRequest({ apiPath: 'crm/instances/facture/list', type: 'GET' });
        if (response && Array.isArray(response.rows)) {
            setNoteDeCreditData(prevData => ({
                ...prevData,
                invoiceOptions: response.rows.map(invoice => ({
                    label: `${invoice.num_document} - ${invoice.date_emission}`,
                    value: invoice.id,
                }))
            }));
        }
    };

    useEffect(() => {
        fetchInvoices();
    }, [sendRequest]);

    const handleSubmit = async () => {
        
        // Convert line item amounts to numbers
        const formattedNoteDeCreditData = {
            ...noteDeCreditData,
            lineItems: noteDeCreditData.lineItems.map(item => ({
                ...item,
                quantity: parseFloat(item.quantity),
                price: parseFloat(item.price),
                tax: parseFloat(item.tax)
            }))
        };

        console.log('Note de Credit Data:', formattedNoteDeCreditData);
        const result = await sendRequestWithToast({
            apiPath: 'notecredit/create',
            type: 'POST',
            request: formattedNoteDeCreditData,
            successMessage: "Note de crédit créée avec succès!",
            errorMessage: "Erreur lors de la création de la note de crédit",
        });

        if (result && result.success) {
            onCreditNoteCreated();
        }
    };

    const handleInputChange = (name, value) => {
        setNoteDeCreditData(prevData => ({
            ...prevData,
            [name]: value,
        }));
    };

    const ncStatusOptions = [
        { label: 'Brouillon', value: 'Brouillon' },
        { label: 'Envoyée', value: 'Envoyée' },
    ];

    return (
        <Card variant="level1">
            <CardContent>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant='h5' color='blue-gray'>
                        Nouvelle Note de Crédit
                    </Typography>
                    <IconButton onClick={() => setIsFormExpanded(!isFormExpanded)}>
                        {isFormExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </IconButton>
                </Box>
                {isFormExpanded && (
                    <>
                        <DropdownWithIcon
                            label="Client"
                            iconName="account-circle"
                            value={noteDeCreditData.customer}
                            onChange={(e) => handleInputChange('customer', e.target.value)}
                            options={[{ label: 'Sélectionnez un client', value: '' }, ...customers]}
                        />
                        <DropdownWithIcon
                            label="Facture de référence"
                            iconName="file-document-outline"
                            value={noteDeCreditData.invoice}
                            onChange={(e) => handleInputChange('invoice', e.target.value)}
                            options={[{ label: 'Sélectionnez une facture', value: '' }, ...noteDeCreditData.invoiceOptions]}
                        />
                        <DropdownWithIcon
                            label="Statut de la Note de Crédit"
                            iconName="list"
                            value={noteDeCreditData.ncStatus}
                            onChange={(e) => handleInputChange('ncStatus', e.target.value)}
                            options={ncStatusOptions}
                        />
                        <TextInputWithIcon
                            label="Date de la Note de Crédit"
                            iconName="calendar"
                            type="date"
                            value={noteDeCreditData.ncDate}
                            onChange={(e) => handleInputChange('ncDate', e.target.value)}
                        />
                        <TextInputWithIcon
                            label="Motif"
                            iconName="text-box-outline"
                            type="text"
                            value={noteDeCreditData.motif} // Ajouter le champ motif
                            onChange={(e) => handleInputChange('motif', e.target.value)}
                        />
                        <LineItemTable
                            lineItems={noteDeCreditData.lineItems}
                            onLineItemChange={handleLineItemChange}
                        />
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={handleSubmit}
                        >
                            Créer la Note de Crédit
                        </Button>
                    </>
                )}
            </CardContent>
        </Card>
    );
};

export default CreditNoteForm;
