import React from 'react';

const PDFViewerObject = ({ filePath }) => {
    return (
        <object
            data={filePath}
            type="application/pdf"
            width="100%"
            height="600px"
        >
            <p>Votre navigateur ne peut pas afficher le PDF. Vous pouvez le télécharger à <a href={filePath}>ce lien</a>.</p>
        </object>
    );
};

export default PDFViewerObject;
