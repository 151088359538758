import React, { useState, useContext, useEffect } from 'react';
import { DropdownWithIcon, TextInputWithIcon } from '../../Components/InputFields';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Box from '@mui/material/Box';
import LineItemTable from './LineItemTable';
import Modal from '../../Components/Modal';
import { HttpRequestContext } from '../../Components/HttpRequestProvider';

const RecuForm = ({ billingprofiles, customers, onRecuCreated, selectedBillingProfileId }) => {
    const { sendRequestWithToast, sendRequest } = useContext(HttpRequestContext);
    const [recuData, setRecuData] = useState({
        billingprofile: selectedBillingProfileId,
        lineItems: [],
        recuDate: '',
        dueDate: '',
        recuStatus: 'Non Payé',
        customer: '',
        commentaire: '', // Add commentaire field here
    });

    useEffect(() => {
        setRecuData((prevData) => ({
            ...prevData,
            billingprofile: selectedBillingProfileId,
        }));
    }, [selectedBillingProfileId]);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [clientTypeMetadata, setClientTypeMetadata] = useState(null);
    const [formValues, setFormValues] = useState({});
    const [isFormExpanded, setIsFormExpanded] = useState(false);

    useEffect(() => {
        const fetchClientMetadata = async () => {
            try {
                const clientMetadataResponse = await sendRequest({
                    apiPath: `crm/types/read/client`,
                    type: 'GET',
                });
                console.log("Métadonnées client récupérées:", clientMetadataResponse);
                setClientTypeMetadata(clientMetadataResponse);
            } catch (err) {
                console.error("Erreur lors de la récupération des métadonnées client:", err);
            }
        };

        fetchClientMetadata();
    }, [sendRequest]);

    const handleLineItemChange = (lineItems) => {
        setRecuData(prevData => ({
            ...prevData,
            lineItems
        }));
    };

    const handleSubmit = async () => {
        // Convert line item amounts to numbers
        const formattedRecuData = {
            ...recuData,
            lineItems: recuData.lineItems.map(item => ({
                ...item,
                quantity: parseFloat(item.quantity),
                price: parseFloat(item.price),
                tax: parseFloat(item.tax)
            }))
        };

        console.log('Formatted Recu Data:', formattedRecuData);
        
        const result = await sendRequestWithToast({
            apiPath: 'recu/create',
            type: 'POST',
            request: formattedRecuData,
            successMessage: "Reçu créé avec succès!",
            errorMessage: "Erreur lors de la création du reçu",
        });

        if (result && result.success) {
            onRecuCreated(); // Assurez-vous que la fonction est appelée ici
        }
    };

    const handleInputChange = (name, value) => {
        setRecuData(prevData => ({
            ...prevData,
            [name]: value,
        }));
    };

    const recuStatusOptions = [
        { label: 'Non Payé', value: 'Non Payé' },
        { label: 'Payé', value: 'Payé' },
    ];

    const handleFormInputChange = (name, value) => {
        setFormValues(prevValues => ({
            ...prevValues,
            [name]: value,
        }));
    };

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setFormValues({});
    };

    const handleCreateclient = async () => {
        const formData = new FormData();
        Object.keys(formValues).forEach(key => {
            if (formValues[key] instanceof File) {
                formData.append(key, formValues[key]);
            } else {
                formData.append(key, formValues[key]);
            }
        });

        try {
            await sendRequestWithToast({
                apiPath: `/crm/instances/client/create`,
                type: 'POST',
                request: formData,
                successMessage: "client créé avec succès",
                errorMessage: "Erreur lors de la création du client",
            });
            setIsModalOpen(false);
            setFormValues({});
            onRecuCreated(); // Assurez-vous que la fonction est appelée ici
        } catch (err) {
            console.error("Erreur lors de la création du client:", err);
        }
    };

    const renderInputField = (key, property) => {
        if (property.type === 'label') {
            const dropdownOptions = property.accepted_values.map(value => ({
                label: value,
                value: value
            }));

            return (
                <DropdownWithIcon
                    key={key}
                    id={key}
                    label={property.display_name}
                    iconName="label"
                    value={formValues[key] || property.accepted_values[0]}
                    onChange={(e) => handleFormInputChange(key, e.target.value)}
                    options={dropdownOptions}
                />
            );
        } else {
            return (
                <TextInputWithIcon
                    key={key}
                    id={key}
                    type="text"
                    placeholder={property.value}
                    label={property.display_name}
                    iconName="text"
                    value={formValues[key] || ''}
                    onChange={(e) => handleFormInputChange(key, e.target.value)}
                    name={key}
                />
            );
        }
    };

    return (
        <Card variant="level1">
            <CardContent>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant='h5' color='blue-gray'>
                        Nouveau Reçu
                    </Typography>
                    <IconButton onClick={() => setIsFormExpanded(!isFormExpanded)}>
                        {isFormExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </IconButton>
                </Box>
                {isFormExpanded && (
                    <>
                        <div className="flex flex-wrap -mx-3 mb-6">
                            <div className="px-3 mb-6 md:mb-0 w-full md:w-1/3">
                                <DropdownWithIcon
                                    label="Client"
                                    iconName="account-circle"
                                    value={recuData.customer}
                                    onChange={(e) => handleInputChange('customer', e.target.value)}
                                    options={[{ label: 'Sélectionnez un client', value: '' }, ...customers]}
                                />
                            </div>
                            <div className="px-3 w-full md:w-1/3 flex justify-end items-end">
                                <Button
                                    color="primary"
                                    variant="contained"
                                    ripple="true"
                                    onClick={handleOpenModal}
                                >
                                    Nouveau client
                                </Button>
                            </div>
                        </div>
                        <div className="flex flex-wrap -mx-3 mb-6">
                            <div className="px-3 mb-6 md:mb-0 w-full md:w-1/3">
                                <TextInputWithIcon
                                    label="Date de Reçu"
                                    iconName="calendar"
                                    type="date"
                                    value={recuData.recuDate}
                                    onChange={(e) => handleInputChange('recuDate', e.target.value)}
                                />
                            </div>
                            <div className="px-3 mb-6 md:mb-0 w-full md:w-1/3">
                                <TextInputWithIcon
                                    label="Date d'Échéance"
                                    iconName="calendar"
                                    type="date"
                                    value={recuData.dueDate}
                                    onChange={(e) => handleInputChange('dueDate', e.target.value)}
                                />
                            </div>
                        </div>

                        <LineItemTable
                            lineItems={recuData.lineItems}
                            onLineItemChange={handleLineItemChange}
                        />
                        <div className="w-full px-3 mb-6">
                            <DropdownWithIcon
                                label="Statut du Reçu"
                                iconName="list"
                                value={recuData.recuStatus}
                                onChange={(e) => handleInputChange('recuStatus', e.target.value)}
                                options={recuStatusOptions}
                            />
                        </div>
                        <div className="w-full px-3 mb-6"> {/* Add this block for commentaire */}
                            <TextInputWithIcon
                                label="Commentaire"
                                iconName="comment"
                                type="text"
                                value={recuData.commentaire}
                                onChange={(e) => handleInputChange('commentaire', e.target.value)}
                            />
                        </div>
                        <div className="px-3">
                            <Button
                                color='primary'
                                variant="contained"
                                ripple="true"
                                onClick={handleSubmit}
                            >
                                Créer le Reçu
                            </Button>
                        </div>
                    </>
                )}
            </CardContent>

            <Modal isOpen={isModalOpen} closeModal={handleCloseModal}>
                <Card className='bg-gray-200 w-full'>
                    <CardContent>
                        <Typography variant='h5' color='blue-gray' className="mb-4">
                            Créer un Nouveau client
                        </Typography>
                        {clientTypeMetadata && Object.entries(clientTypeMetadata.properties).map(([key, property]) =>
                            renderInputField(key, property)
                        )}
                        <Button
                            color='primary'
                            variant="contained"
                            ripple="true"
                            className='w-full md:w-auto mt-4'
                            onClick={handleCreateclient}
                        >
                            Créer
                        </Button>
                    </CardContent>
                </Card>
            </Modal>
        </Card>
    );
};

export default RecuForm;
