import React from 'react';
import { FormControl, InputLabel, Select, MenuItem, TextField, Button } from '@mui/material';

const PropertyInputField = ({ property, value, onChange }) => {
  const handleChange = (e) => {
    const newValue = property.type === 'image' ? e.target.files[0] : e.target.value;
    onChange(property.property_name, newValue);
  };

  if (property.type === 'label') {
    if (property.accepted_values.length === 0) return null;
    const dropdownOptions = property.accepted_values.map((val) => ({
      label: val,
      value: val,
    }));

    return (
      <FormControl fullWidth margin="normal" required>
        <InputLabel>{property.display_name}</InputLabel>
        <Select
          id={property.property_name}
          value={value || property.accepted_values[0]}
          onChange={handleChange}
        >
          {dropdownOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  } else if (property.type === 'image') {
    return (
      <Button variant="contained" component="label" margin="normal">
        {property.display_name}
        <input type="file" hidden onChange={handleChange} accept="image/*" />
      </Button>
    );
  } else {
    return (
      <TextField
        id={property.property_name}
        type="text"
        placeholder={property.value}
        label={property.display_name}
        value={value || ''}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
    );
  }
};

export default PropertyInputField;
