import React from 'react';
import Sidebar from '../../Components/Sidebar';
import { ImportContacts, AttachMoney, Receipt, Description } from '@mui/icons-material';

const TransactionSidebar = ({ isSidebarOpen, setIsSidebarOpen, handleSectionChange }) => {
    const categories = [
        {
            key: 'bulkimport',
            label: 'Bulk Import',
            icon: <ImportContacts />,
            route: '/transactions/bulk-import'
        },
        {
            key: 'matching',
            label: 'Matching Client',
            icon: <AttachMoney />,
            route: '/transactions/matching'
        },
        {
            key: 'invoice-matching',
            label: 'Matching Factures',
            icon: <Receipt />,
            route: '/transactions/invoice-matching'
        }
    ].map(section => ({
        title: section.label,
        icon: section.icon,
        key: section.key,
        route: section.route
    }));

    return (
        <Sidebar
            sidebarOpen={isSidebarOpen}
            setSidebarOpen={setIsSidebarOpen}
            categories={categories}
            selectedLabel={null} // Assuming there is no profile label for transactions
            onSelectChange={handleSectionChange}
            showProfileButton={false} // Set to false to hide the profile button
        />
    );
};

export default TransactionSidebar;
