import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { UserContext } from '../UserProvider';

const AdminRoute = ({ element: Component }) => {
    const { user, isLoading } = useContext(UserContext);

    if (isLoading) {
        // Afficher un spinner de chargement ou un message pendant le chargement
        return <div>Loading...</div>;
    }

    return user && user.admin ? (
        Component
    ) : (
        <Navigate to="/" />
    );
};

export default AdminRoute;
