import React, { useState, useEffect, useContext } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import { HttpRequestContext } from '../../Components/HttpRequestProvider';
import { Icon } from '../../Components/Icons';

const DefineCRMTypeChildren = ({ typeName, onClose }) => {
    const [childObjects, setChildObjects] = useState([]);
    const [availableChildTypes, setAvailableChildTypes] = useState([]);
    const [selectedChildType, setSelectedChildType] = useState('');
    const { sendRequestWithToast, sendRequest } = useContext(HttpRequestContext);

    useEffect(() => {
        // Fetch child objects
        sendRequest({
            apiPath: `/crm/type/${typeName}/childs`,
            type: 'GET'
        })
        .then(data => {
            setChildObjects(data.childs);
            fetchAvailableChildTypes(data.childs);
        })
        .catch(error => console.error("Failed to fetch child objects:", error));
    }, [typeName, sendRequest]);

    const fetchAvailableChildTypes = (existingChilds) => {
        sendRequest({
            apiPath: `/crm/types/list`,
            type: 'GET'
        })
        .then(data => {
            data = data.rows;
            const filteredTypes = data.filter(type => 
                type.type_name !== typeName && 
                !existingChilds.some(child => child.type_name === type.type_name)
            );
            setAvailableChildTypes(filteredTypes);
        })
        .catch(error => console.error("Failed to fetch CRM types:", error));
    };

    const handleAddChild = () => {
        if (!selectedChildType) return;

        const selectedType = availableChildTypes.find(type => type.type_name === selectedChildType);
        if (!selectedType) return;

        if (!childObjects.some(child => child.type_name === selectedChildType)) {
            setChildObjects([...childObjects, selectedType]);
            setSelectedChildType('');
        }
    };

    const handleDeleteChild = (childTypeName) => {
        setChildObjects(childObjects.filter(child => child.type_name !== childTypeName));
    };

    const handleSaveChanges = async () => {
        const childTypeNames = childObjects.map(child => child.type_name);
        const success = await sendRequestWithToast({
            apiPath: `/crm/type/${typeName}/childs`,
            type: 'POST',
            request: { child_type_names: childTypeNames },
            successMessage: "Objects liés mis à jours.",
            errorMessage: "Failed to update child types."
        });
        if (success) {
            onClose();
        }
    };

    return (
        <Card variant="outlined" className="p-6">
            <CardContent>
                <Typography variant='h5' color='textPrimary'>
                    Objects enfants de: {typeName}
                </Typography>

                {childObjects.map((object, index) => (
                    <div key={index} className="flex justify-between items-center p-2 bg-white my-2 rounded shadow">
                        <span className="text-sm">{object.display_name}</span>
                        <IconButton onClick={() => handleDeleteChild(object.type_name)} color="secondary">
                            <Icon iconName="delete" type="mdi" />
                        </IconButton>
                    </div>
                ))}

                <div className="my-4">
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={9}>
                            <Select
                                value={selectedChildType}
                                onChange={(e) => setSelectedChildType(e.target.value)}
                                variant="outlined"
                                fullWidth
                            >
                                <MenuItem value="">Choisir un object</MenuItem>
                                {availableChildTypes.map(type => (
                                    <MenuItem key={type.type_name} value={type.type_name}>{type.display_name}</MenuItem>
                                ))}
                            </Select>
                        </Grid>
                        <Grid item xs={3}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleAddChild}
                                fullWidth
                            >
                                Ajouter un object dépendant
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            </CardContent>

            <div className="flex justify-end mt-4">
                <Button variant="contained" color="primary" onClick={handleSaveChanges}>
                    Sauver
                </Button>
            </div>
        </Card>
    );
};

export default DefineCRMTypeChildren;
