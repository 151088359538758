import React from 'react';
import Sidebar from '../../Components/Sidebar';
import { Description, Receipt, Note } from '@mui/icons-material';

const AccountingSidebar = ({ isSidebarOpen, setIsSidebarOpen, handleSectionChange, getSelectedBillingProfileLabel, openBillingProfileModal }) => {
    const categories = [
        {
            key: 'facture',
            label: 'Facture',
            icon: <Description />,
        },
        {
            key: 'recu',
            label: 'Reçu',
            icon: <Receipt />,
        },
        {
            key: 'notedecredit',
            label: 'Note de Crédit',
            icon: <Note />,
        }
    ].map(section => ({
        title: section.label,
        icon: section.icon,
        key: section.key
    }));

    return (
        <Sidebar
            sidebarOpen={isSidebarOpen}
            setSidebarOpen={setIsSidebarOpen}
            categories={categories}
            selectedLabel={getSelectedBillingProfileLabel()}
            onSelectChange={handleSectionChange}
            showProfileButton={true}
            onProfileButtonClick={() => {
                console.log("Profile button clicked"); // Ajoutez ce log
                openBillingProfileModal();
            }}
        />
    );
};

export default AccountingSidebar;
