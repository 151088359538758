import React from 'react';
export function HorizontalSeparator({ label }) {
    return (
      <div className="flex items-center">
        <hr className="w-full border border-gray-300" />
        <div className="font-semibold text-gray-400">{label}</div>
        <hr className="w-full border border-gray-300" />
      </div>
    );
  }
  