// UserSearch.js modification
import React, { useState, useEffect, useContext } from 'react';
import { Box, TextField, Button, Grid, Autocomplete } from '@mui/material';
import { HttpRequestContext } from '../../Components/HttpRequestProvider';

const UserSearch = ({ onSearch }) => {
    const { sendRequest } = useContext(HttpRequestContext);
    const [searchQuery, setSearchQuery] = useState('');
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await sendRequest({
                    apiPath: 'admin/users',
                    type: 'GET'
                });
                setUsers(response.users);
            } catch (error) {
                console.error('Error fetching users:', error);
            }
        };

        fetchUsers();
    }, [sendRequest]);

    const handleSearch = () => {
        if (selectedUser) {
            onSearch(selectedUser);
        }
    };

    return (
        <Box sx={{ mb: 4 }}>
            <Grid container spacing={2}>
                <Grid item xs={10}>
                    <Autocomplete
                        options={users}
                        getOptionLabel={(option) => `${option.name} (${option.email})`}
                        onChange={(event, newValue) => {
                            setSelectedUser(newValue);
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Rechercher un utilisateur"
                                variant="outlined"
                                fullWidth
                                onChange={(e) => setSearchQuery(e.target.value)}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={2}>
                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={handleSearch}
                    >
                        Rechercher
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
};

export default UserSearch;
