import React, { useState, useEffect, useContext } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Select from '@mui/material/Select';
import { HttpRequestContext } from '../../Components/HttpRequestProvider';
import { Icon } from '../../Components/Icons';
import { TagInputWithIcon } from '../../Components/InputFields';

const AddStringProperty = ({ newProperty, setNewProperty }) => (
    <TextField
        name='default_value'
        value={newProperty.default_value}
        onChange={(e) => setNewProperty(prev => ({ ...prev, default_value: e.target.value }))}
        placeholder='Valeur par défaut'
        label='Valeur par défaut'
        variant='outlined'
        fullWidth
        margin="normal"
    />
);

const AddDateProperty = () => (
    <></>
);

const AddLabelProperty = ({ newProperty, setNewProperty, accepted_values, setaccepted_values }) => (
    <>
        <TagInputWithIcon
            id="tag-input"
            placeholder="Entrer des options (appuyez sur enter après chaque option)"
            label="Tags"
            value={accepted_values}
            onChange={setaccepted_values}
            name="tags"
        />

        <Select
            value={newProperty.default_value || ""}
            onChange={(e) => setNewProperty(prev => ({ ...prev, default_value: e.target.value }))}
            fullWidth
            variant='outlined'
            margin="normal"
        >
            {accepted_values.map(type => (
                <MenuItem key={type} value={type}>{type}</MenuItem>
            ))}
        </Select>
    </>
);

const AddPhoneProperty = ({ newProperty, setNewProperty }) => (
    <TextField
        name='default_value'
        value={newProperty.default_value}
        onChange={(e) => setNewProperty(prev => ({ ...prev, default_value: e.target.value }))}
        placeholder='Valeur par défaut'
        label='Valeur par défaut'
        variant='outlined'
        fullWidth
        margin="normal"
    />
);

const AddEmailProperty = ({ newProperty, setNewProperty }) => (
    <TextField
        name='default_value'
        value={newProperty.default_value}
        onChange={(e) => setNewProperty(prev => ({ ...prev, default_value: e.target.value }))}
        placeholder='Valeur par défaut'
        label='Valeur par défaut'
        variant='outlined'
        fullWidth
        margin="normal"
    />
);

const AddScheduleProperty = () => (
    <></>
);

const AddNumberProperty = ({ newProperty, setNewProperty }) => (
    <TextField
        name='default_value'
        type="number"
        value={newProperty.default_value}
        onChange={(e) => setNewProperty(prev => ({ ...prev, default_value: e.target.value }))}
        placeholder='Valeur par défaut'
        label='Valeur par défaut'
        variant='outlined'
        fullWidth
        margin="normal"
    />
);

const AddCurrencyAmountProperty = ({ newProperty, setNewProperty }) => (
    <>
        <TextField
            name='amount'
            type="number"
            value={newProperty.amount || ''}
            onChange={(e) => setNewProperty(prev => ({ ...prev, amount: e.target.value }))}
            placeholder='Montant'
            label='Montant'
            variant='outlined'
            fullWidth
            margin="normal"
        />
        <Select
            name='currency'
            value={newProperty.currency || 'EUR'}
            onChange={(e) => setNewProperty(prev => ({ ...prev, currency: e.target.value }))}
            fullWidth
            variant='outlined'
            margin="normal"
        >
            <MenuItem value="EUR">EUR (€)</MenuItem>
            <MenuItem value="USD">USD ($)</MenuItem>
            <MenuItem value="GBP">GBP (£)</MenuItem>
            <MenuItem value="CHF">CHF (CHF)</MenuItem>
        </Select>
    </>
);

const AddProperty = ({ newProperty, setNewProperty, accepted_values, setaccepted_values }) => {
    switch (newProperty.type) {
        case 'string':
            return <AddStringProperty newProperty={newProperty} setNewProperty={setNewProperty} />;
        case 'date':
            return <AddDateProperty />;
        case 'label':
            return <AddLabelProperty newProperty={newProperty} setNewProperty={setNewProperty} accepted_values={accepted_values} setaccepted_values={setaccepted_values} />;
        case 'phone':
            return <AddPhoneProperty newProperty={newProperty} setNewProperty={setNewProperty} />;
        case 'email':
            return <AddEmailProperty newProperty={newProperty} setNewProperty={setNewProperty} />;
        case 'schedule':
            return <AddScheduleProperty />;
        case 'number':
            return <AddNumberProperty newProperty={newProperty} setNewProperty={setNewProperty} />;
        case 'currency_amount':
            return <AddCurrencyAmountProperty newProperty={newProperty} setNewProperty={setNewProperty} />;
        default:
            return null;
    }
};

const EditCRMTypeProperties = ({ typeName, onClose }) => {
    const [properties, setProperties] = useState({});
    const [newProperty, setNewProperty] = useState({ type: "string" });
    const { sendRequestWithToast, sendRequest } = useContext(HttpRequestContext);
    const [accepted_values, setaccepted_values] = useState([]);
    const [availableTypes, setAvailableTypes] = useState([]);

    useEffect(() => {
        const types = [
            { value: "string", label: "Texte" },
            { value: "date", label: "Date" },
            { value: "label", label: "Étiquette" },
            { value: "phone", label: "Téléphone" },
            { value: "email", label: "Email" },
            { value: "schedule", label: "Horaire" },
            { value: "number", label: "Nombre" },
            { value: "currency_amount", label: "Montant en devise" }
        ];
        setAvailableTypes(types);
    }, []);

    useEffect(() => {
        if (typeName) {
            sendRequest({
                apiPath: `/crm/types/read/${typeName}`,
                type: 'GET'
            })
            .then(data => {
                const transformedProperties = {};

                for (let propName in data.properties) {
                    transformedProperties[propName] = {
                        type: data.properties[propName].type,
                        default_value: data.properties[propName].value
                    };
                }

                setProperties(transformedProperties);
            })
            .catch(error => {
                console.error("Failed to fetch properties:", error);
            });
        }
    }, [typeName, sendRequest]);

    const handleSaveProperties = async () => {
        const success = await sendRequestWithToast({
            apiPath: `/crm/type/${typeName}/properties`,
            type: 'POST',
            request: { properties: properties },
            successMessage: "Properties updated successfully.",
            errorMessage: "Failed to update properties."
        });
        if (success) {
            onClose();
        }
    };

    const handleAddProperty = () => {
        setProperties({
            ...properties,
            [newProperty.name]: {
                type: newProperty.type,
                default_value: newProperty.type === "date" || newProperty.type === "schedule" ? null : newProperty.default_value,
                accepted_values: accepted_values
            }
        });
        setNewProperty({});
    };

    const handleDeleteProperty = (propertyName) => {
        const updatedProperties = { ...properties };
        delete updatedProperties[propertyName];
        setProperties(updatedProperties);
    };

    return (
        <Card variant="outlined" className="p-6">
            <CardContent>
                <Typography variant="h5" color="textPrimary">
                    Variables actuelles
                </Typography>
                {Object.keys(properties).map(propertyName => (
                    <div key={propertyName} className="flex justify-between items-center p-2 bg-white my-2 rounded shadow">
                        <span className="text-sm">
                            <strong>Type:</strong> {properties[propertyName].type}, 
                            <strong>Nom:</strong> {propertyName}, 
                            <strong>Standard:</strong> {properties[propertyName].default_value}
                        </span>
                        <IconButton onClick={() => handleDeleteProperty(propertyName)} color="secondary">
                            <Icon iconName="delete" type="mdi" />
                        </IconButton>
                    </div>
                ))}
            </CardContent>
            
            <CardContent>
                <Typography variant="h5" color="textPrimary">
                    Ajouter une variable
                </Typography>
                <div className="space-y-3">
                    <Select
                        value={newProperty.type}
                        onChange={(e) => {
                            setNewProperty(prev => ({ ...prev, type: e.target.value }));
                            if (e.target.value === "label") {
                                setaccepted_values(["Option1", "Option2"]);
                            }
                        }}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                    >
                        {availableTypes.map(type => (
                            <MenuItem key={type.value} value={type.value}>{type.label}</MenuItem>
                        ))}
                    </Select>

                    <TextField
                        name='name'
                        value={newProperty.name || ""}
                        onChange={(e) => setNewProperty(prev => ({ ...prev, name: e.target.value }))}
                        placeholder='Nom'
                        label='Nom'
                        variant='outlined'
                        fullWidth
                        margin="normal"
                    />

                    <AddProperty newProperty={newProperty} setNewProperty={setNewProperty} accepted_values={accepted_values} setaccepted_values={setaccepted_values} />

                    <Button variant='contained' color='primary' onClick={handleAddProperty}>
                        <Icon iconName="plus" type="mdi" />
                        <span>Ajouter</span>
                    </Button>
                </div>
            </CardContent>

            <div className="flex justify-end mt-4">
                <Button variant='contained' color='primary' onClick={handleSaveProperties}>
                    Sauver
                </Button>
            </div>
        </Card>
    );
};

export default EditCRMTypeProperties;
