import React, { createContext } from 'react';
import { successToast, errorToast } from './Toasts';

export const HttpRequestContext = createContext({
    sendRequest: () => {},
    sendRequestWithToast: () => {},
});

export function getBaseDomain(url) {
    const hostname = (typeof url === 'string') ? (new URL(url)).hostname : window.location.hostname;
    return hostname.replace("app.","");
}

export const HttpRequestProvider = ({ children }) => {

    const sendRequest = async ({ apiPath, type, request, headers }) => {
        const baseDomain = getBaseDomain();
    
        if (apiPath.startsWith('/')) {
            apiPath = apiPath.slice(1);
        }
    
        const apiUrl = `//${baseDomain}/api/${apiPath}`; 
        headers = { 
            ...{ 'Content-Type': 'application/json; charset=utf-8' }, 
            ...headers 
        };
    
        if (!(request instanceof FormData)) {
            request = JSON.stringify(request);
        } else {
            delete headers['Content-Type']; // Le navigateur définira le bon 'Content-Type' pour FormData
        }
    
        try {
            const response = await fetch(apiUrl, {
                method: type,
                headers: headers,
                body: request,
                credentials: 'include'
            });
    
            if (!response.ok) {
                // Traiter la réponse comme JSON pour extraire le message d'erreur
                const errorData = await response.json();
                const serverMessage = errorData && errorData.message ? errorData.message : "Error occurred";
                throw new Error(serverMessage);
            }
    
            // Vérifier le type de contenu de la réponse
            const contentType = response.headers.get('Content-Type');
            if (contentType && contentType.includes('application/json')) {
                // Traiter la réponse comme JSON
                return await response.json();
            } else {
                // Traiter la réponse comme un blob binaire si ce n'est pas du JSON
                return await response.blob();
            }
        } catch (error) {
            throw error;
        }
    };
    const sendRequestWithToast = async ({
        apiPath,
        request,
        type,
        successMessage,
        errorMessage,
        successRedirectUrl,
        needRecaptcha,
        recaptchaRef
    }) => {
        // Check for reCAPTCHA
        if (needRecaptcha && (!request.recaptchaResponse || request.recaptchaResponse.length === 0)) {
            errorToast("Merci de prouver que vous n'êtes pas un robot.");
            return;
        }

        // Define headers
        const headers = {
            "Content-Type": "application/json; charset=utf-8",
            "time-zone": Intl.DateTimeFormat().resolvedOptions().timeZone
        };

        try {
            const responseData = await sendRequest({ apiPath, type, request, headers });
            successToast(successMessage);
    
            if (successRedirectUrl) {
                window.location.href = successRedirectUrl;
            }
            return responseData;  // Return true for a successful request
        } catch (error) {
            errorToast(error.message || errorMessage);
    
            if (needRecaptcha && recaptchaRef.current) {
                recaptchaRef.current.reset();
            }
            console.error("Error occurred:", error);
            return false;  // Return false for a failed request
        }
    }

    return (
        <HttpRequestContext.Provider value={{ sendRequest, sendRequestWithToast }}>
            {children}
        </HttpRequestContext.Provider>
    );
};

export default HttpRequestProvider;
