import React, { useEffect, useState, useContext } from 'react';
import Dashboard from '../Layouts/Dashboard';
import PhoneInput from 'react-phone-input-2';
import { BookingCard } from './Modules/BookingCard';
import { UserContext } from '../Components/UserProvider';
import 'react-phone-input-2/lib/style.css';
import KanbanBoard from '../Components/Kanban';
import { LockedFeatureCard } from '../Components/Cards';
import { HttpRequestContext } from '../Components/HttpRequestProvider';

const MyWebsite = () => {
    const { user } = useContext(UserContext);

    const [onboardingData, setOnboardingData] = useState(null);
    const { sendRequest } = useContext(HttpRequestContext);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await sendRequest({
                    apiPath: '/user/onboarding',
                    type: 'GET'
                });
                setOnboardingData(result);
                console.log(result);
            } catch (error) {
                console.error("Error fetching user onboarding data:", error);
            }
        };

        fetchData();
    }, [sendRequest]);

    // Define the image source based on onboardingData
    const imageSrc = onboardingData && onboardingData.selectedTemplate
        ? `/dist/img/templates/${onboardingData.selectedTemplate.image_file}`
        : "/dist/img/templates/adaptm5-projects-coaching-classes-cover.jpg";

    return (
        <Dashboard dashboardTitle={"Dashboard"}>
            <BookingCard /> 
        </Dashboard>
    );
};

export default MyWebsite;
