import React, { useCallback, useRef, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import ReactFlow, {
  MiniMap,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  addEdge,
  MarkerType,
  useReactFlow,
  useViewport,
} from 'reactflow';
import 'reactflow/dist/style.css';
import './Flow.css';
import dagre from 'dagre';

import Toolbar from './Toolbar';
import Dashboard from '../../Layouts/Dashboard';
import {
  TriggerNode,
  WaitNode,
  EditNode,
  SendEmailNode,
  IfElseNode,
} from './CustomNodes';
import { HttpRequestContext } from '../../Components/HttpRequestProvider';

const initialNodes = [];
const initialEdges = [];

const defaultEdgeStyle = {
  markerEnd: { type: MarkerType.ArrowClosed, width: 10, height: 10, color: '#000000' },
  style: { strokeWidth: 2, stroke: '#000000' },
};

const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

const getNodeDimensions = (nodes) => {
  const nodeDimensions = {};
  nodes.forEach((node) => {
    const nodeElement = document.querySelector(`[data-id="${node.id}"]`);
    if (nodeElement) {
      const { width, height } = nodeElement.getBoundingClientRect();
      nodeDimensions[node.id] = { width, height };
    }
  });
  return nodeDimensions;
};

const getLayoutedNodesAndEdges = (nodes, edges, nodeDimensions, direction = 'TB') => {
  const isHorizontal = direction === 'LR';
  const maxNodeWidth = Math.max(...Object.values(nodeDimensions).map(d => d.width));
  const maxNodeHeight = Math.max(...Object.values(nodeDimensions).map(d => d.height));

  dagreGraph.setGraph({
    rankdir: direction,
    nodesep: maxNodeWidth + 50, // Additional padding
    ranksep: maxNodeHeight + 50, // Additional padding
  });

  nodes.forEach((node) => {
    const { width, height } = nodeDimensions[node.id] || { width: 150, height: 50 };
    dagreGraph.setNode(node.id, { width, height });
  });

  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  dagre.layout(dagreGraph);

  const layoutedNodes = nodes.map((node) => {
    const nodeWithPosition = dagreGraph.node(node.id);
    return {
      ...node,
      position: {
        x: nodeWithPosition.x - nodeWithPosition.width / 2,
        y: nodeWithPosition.y - nodeWithPosition.height / 2,
      },
    };
  });

  return { nodes: layoutedNodes, edges };
};


const nodeTypes = {
  trigger: TriggerNode,
  wait: WaitNode,
  edit: EditNode,
  sendEmail: SendEmailNode,
  ifElse: IfElseNode,
};

function Flow() {
  const { sendRequestWithToast } = useContext(HttpRequestContext);
  const { typeName } = useParams();
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const { project, fitView, setViewport } = useReactFlow();
  const { x, y, zoom } = useViewport();
  const connectingNodeId = useRef(null);
  const [loaded, setLoaded] = useState(false);
  const nodeDimensions = useRef({});

  useEffect(() => {
    const loadFlow = async () => {
      const response = await sendRequestWithToast({
        apiPath: `/workflow/load?type_name=${typeName}`,
        type: 'GET',
        successMessage: 'Flow loaded successfully!',
        errorMessage: 'Error loading flow.',
      });

      if (response.success) {
        const { nodes: loadedNodes, edges: loadedEdges } = response.workflow.content;
        const updatedNodes = loadedNodes.map(node => ({
          ...node,
          data: {
            ...node.data,
            workflowData: node.data.workflowData || {}, // Ensure workflowData is properly set
          },
        }));
        setNodes(updatedNodes);
        setEdges(loadedEdges);
        setLoaded(true);
      }
    };

    loadFlow();
  }, [typeName, sendRequestWithToast, setNodes, setEdges]);

  useEffect(() => {
    if (loaded) {
      fitView({ padding: 0.5, includeHiddenNodes: false, duration: 0 });
      setLoaded(false);
    }
  }, [loaded, fitView, setViewport, zoom]);

  const onConnect = useCallback(
    (params) => {
      const newEdge = {
        ...params,
        id: uuidv4(),
        label: ``,
        ...defaultEdgeStyle,
        type: 'smoothstep',
      };
      connectingNodeId.current = null;
      setEdges((eds) => addEdge(newEdge, eds));
    },
    [setEdges]
  );

  const addNode = useCallback((type) => {
    const viewportCenter = project({ x: window.innerWidth / 2, y: window.innerHeight / 2 });
    const offsetX = (Math.random() - 0.5) * 100;
    const offsetY = (Math.random() - 0.5) * 100;

    const newNode = {
      id: uuidv4(),
      type: type,
      position: { x: viewportCenter.x + offsetX, y: viewportCenter.y + offsetY },
      data: { label: `${type}`, workflowData: {} },
    };
    setNodes((nds) => nds.concat(newNode));
  }, [setNodes, project]);

  const applyLayout = useCallback((direction) => {
    const dimensions = getNodeDimensions(nodes);
    nodeDimensions.current = dimensions;
    const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedNodesAndEdges(nodes, edges, dimensions, direction);

    setNodes([...layoutedNodes]);
    setEdges([...layoutedEdges]);
  }, [nodes, edges, setNodes, setEdges]);

  const onConnectStart = useCallback((_, { nodeId }) => {
    connectingNodeId.current = nodeId;
  }, []);

  const saveFlow = useCallback(async () => {
    const nodesToSave = nodes.map(node => ({
      ...node,
      data: {
        ...node.data,
        workflowData: node.data.workflowData || {}, // Ensure workflowData is properly set
      },
    }));

    const response = await sendRequestWithToast({
      apiPath: '/workflow/save',
      type: 'POST',
      request: {
        type_name: typeName,
        content: { nodes: nodesToSave, edges },
      },
      successMessage: 'Flow saved successfully!',
      errorMessage: 'Error saving flow.',
    });

    if (!response.success) {
      alert(`Error: ${response.message}`);
    }
  }, [nodes, edges, sendRequestWithToast, typeName]);

  const clearFlow = useCallback(() => {
    setNodes([]);
    setEdges([]);
  }, [setNodes, setEdges]);

  const onInit = useCallback((reactFlowInstance) => {
    reactFlowInstance.fitView();
  }, []);

  const onNodeDragStop = (event, node) => {
    const { width, height } = event.target.getBoundingClientRect();
    nodeDimensions.current[node.id] = { width, height };
  };

  return (
    <Dashboard dashboardTitle={"Dashboard"} initialFullScreen>
      <div style={{ height: 800 }}>
        <Toolbar addNode={addNode} applyLayout={applyLayout} saveFlow={saveFlow} clearFlow={clearFlow} />
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          onConnectStart={onConnectStart}
          nodeTypes={nodeTypes}
          className="bg-teal-50"
          onInit={onInit}
          onNodeDragStop={onNodeDragStop}
        >
          <MiniMap />
          <Controls />
        </ReactFlow>
      </div>
    </Dashboard>
  );
}

export default Flow;
