import React from 'react';
import './App.css'; 
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Login } from './Pages/Login';
import { OnboardingStart } from './Pages/Onboarding';
import HttpRequestProvider from './Components/HttpRequestProvider';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { UserProvider } from './Components/UserProvider';
import { ThemeProvider } from '@mui/material/styles';
import HomePage from './Pages/Home';
import MyWebsite from './Pages/MyWebsite';
import ManageCRMTypes from './Pages/CRM/ManageCRMTypes';
import CreateCRMInstances from './Pages/CRM/CreateCRMInstances';
import AccountingDashboard from './Pages/Invoicing/AccountingDashboard';
import FormPage from './Pages/Standalone/Forms/FormPage';
import theme from './Theme';
import Flow from './Pages/Flow/Workflow';
import withReactFlowProvider from './Pages/Flow/withReactFlowProvider';
import BulkImportTransactions from './Pages/Import/BulkImportTransactions';
import TransactionMatching from './Pages/Import/TransactionMatching';
import TransactionInvoiceMatching from './Pages/Import/TransactionInvoiceMatching';
import CreateAppointment from './Pages/AppointmentScheduling/CreateAppointment';
import AdminDashboard from './Pages/Admin/AdminDashboard';
import AdminRoute from './Components/Admin/AdminRoute';
import EmailingPage from './Pages/Admin/EmailingPage';
import AdminUsersPage from './Pages/Admin/AdminUsersPage';
import CreateUserForm from './Pages/Admin/CreateUserForm';
import AdminLayout from './Layouts/AdminLayout';

const FlowWithProvider = withReactFlowProvider(Flow);

function App() {
  return (
    <HttpRequestProvider>
      <ThemeProvider theme={theme}>
        <ToastContainer />
        <Router> 
          <UserProvider>
            <Routes>
                <Route path="/" element={<HomePage/>} />
                <Route path="/onboarding" element={<OnboardingStart />} />
                <Route path="/login" element={<Login />} />
                <Route path="/form" element={<FormPage />} />
                <Route path="/my-website" element={<MyWebsite/>} />
                <Route path="/crm/manage-types" element={<ManageCRMTypes />} />
                <Route path="/crm/type/:typeName" element={<CreateCRMInstances />} />
                <Route path="/invoicing" element={<AccountingDashboard />} />
                <Route path="/flow/:typeName" element={<FlowWithProvider />} />
                <Route path="/transactions/bulk-import" element={<BulkImportTransactions />} />
                <Route path="/transactions/matching" element={<TransactionMatching />} />
                <Route path="/transactions/invoice-matching" element={<TransactionInvoiceMatching />} />
                <Route path="/appointments/create" element={<CreateAppointment />} />
                <Route path="/admin" element={<AdminLayout />}>
                    <Route path="dashboard" element={<AdminRoute element={<AdminDashboard />} />} />
                    <Route path="emailing" element={<AdminRoute element={<EmailingPage />} />} />
                    <Route path="users" element={<AdminRoute element={<AdminUsersPage />} />} />
                    <Route path="users/create"  element={<AdminRoute element={<CreateUserForm />} />} />
                </Route>
            </Routes>
          </UserProvider>
        </Router>
      </ThemeProvider>
    </HttpRequestProvider>
  );
}

export default App;
