import React, { useState, useEffect, useContext } from 'react';
import Dashboard from '../../Layouts/Dashboard';
import { HttpRequestContext } from '../../Components/HttpRequestProvider';
import InvoiceForm from './InvoiceForm';
import RecuForm from './RecuForm';
import CreditNoteForm from './CreditNoteForm';
import CommercialDocumentTable from './CommercialDocumentTable';
import AccountingSidebar from './AccountingSidebar';

import { Box, Modal, Paper, FormControl, InputLabel, Select, MenuItem, Typography, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const AccountingDashboard = () => {
    const { sendRequest } = useContext(HttpRequestContext);
    const [billingprofiles, setBillingProfiles] = useState([]);
    const [selectedBillingProfileId, setSelectedBillingProfileId] = useState('');
    const [billingProfileModalOpen, setBillingProfileModalOpen] = useState(false);
    const [customers, setCustomers] = useState([]);
    const [currentSection, setCurrentSection] = useState('');
    const theme = useTheme();
    const isTabletOrMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [isSidebarOpen, setIsSidebarOpen] = useState(!isTabletOrMobile);

    useEffect(() => {
        const fetchBillingProfiles = async () => {
            try {
                const billingprofileResponse = await sendRequest({
                    apiPath: `crm/instances/billingprofile/list`,
                    type: 'GET',
                });
                if (billingprofileResponse && Array.isArray(billingprofileResponse.rows)) {
                    const profileOptions = billingprofileResponse.rows.map(profile => ({
                        label: profile.company_name,
                        value: profile.id,
                    }));
                    setBillingProfiles(profileOptions);

                    const storedBillingProfileId = localStorage.getItem('selectedBillingProfileId');
                    if (!storedBillingProfileId || !profileOptions.some(profile => profile.value === storedBillingProfileId)) {
                        setBillingProfileModalOpen(true);
                    } else {
                        setSelectedBillingProfileId(storedBillingProfileId);
                    }
                }
            } catch (err) {
                console.error("Erreur lors de la récupération des profils de facturation:", err);
            }
        };

        const fetchCustomers = async () => {
            try {
                const customerResponse = await sendRequest({
                    apiPath: `crm/instances/client/list`,
                    type: 'GET',
                });
                if (customerResponse && Array.isArray(customerResponse.rows)) {
                    const customerOptions = customerResponse.rows.map(customer => ({
                        label: `${customer.lastname}, ${customer.firstname}`,
                        value: customer.id,
                    }));
                    setCustomers(customerOptions);
                }
            } catch (err) {
                console.error("Erreur lors de la récupération des clients:", err);
            }
        };

        fetchBillingProfiles();
        fetchCustomers();
    }, [sendRequest]);

    const handleBillingProfileChange = (profileId) => {
        setSelectedBillingProfileId(profileId);
        localStorage.setItem('selectedBillingProfileId', profileId);
    };

    const handleSaveBillingProfile = () => {
        if (!selectedBillingProfileId) {
            alert('Veuillez sélectionner un profil de facturation.');
            return;
        }
        localStorage.setItem('selectedBillingProfileId', selectedBillingProfileId);
        setBillingProfileModalOpen(false);
    };

    const handleSectionChange = (section) => {
        setCurrentSection(section === currentSection ? '' : section);
    };

    const handleInvoiceCreated = () => {
        console.log('Facture créée avec succès!');
    };

    const handleCreditNoteCreated = () => {
        console.log('Note de crédit créée avec succès!');
    };

    const handleRecuCreated = () => {
        console.log('Reçu créé avec succès!');
    };

    const renderContent = () => {
        const selectedSection = {
            facture: (
                <>
                    <InvoiceForm billingprofiles={billingprofiles} customers={customers} onInvoiceCreated={handleInvoiceCreated} selectedBillingProfileId={selectedBillingProfileId} />
                    <CommercialDocumentTable key="facture" apiType="facture" entityName="factures" />
                </>
            ),
            recu: (
                <>
                    <RecuForm billingprofiles={billingprofiles} customers={customers} onRecuCreated={handleRecuCreated} selectedBillingProfileId={selectedBillingProfileId} />
                    <CommercialDocumentTable key="recudecaisse" apiType="recudecaisse" entityName="reçus" />
                </>
            ),
            notedecredit: (
                <>
                    <CreditNoteForm billingprofiles={billingprofiles} customers={customers} onCreditNoteCreated={handleCreditNoteCreated} selectedBillingProfileId={selectedBillingProfileId} />
                    <CommercialDocumentTable key="notedecredit" apiType="notedecredit" entityName="notes de crédit" />
                </>
            ),
        }[currentSection];

        return selectedSection || null;
    };

    const getSelectedBillingProfileLabel = () => {
        const selectedProfile = billingprofiles.find(profile => profile.value === selectedBillingProfileId);
        return selectedProfile ? selectedProfile.label : '';
    };

    return (
        <Dashboard dashboardTitle='Comptabilité' initialFullScreen withSidebar>
            <Box display="flex" height="100%" overflow="hidden">
                <AccountingSidebar
                    isSidebarOpen={isSidebarOpen}
                    setIsSidebarOpen={setIsSidebarOpen}
                    handleSectionChange={handleSectionChange}
                    getSelectedBillingProfileLabel={getSelectedBillingProfileLabel}
                />
                <Box flexGrow={1} p={2} height="100%" overflow="auto">
                    {renderContent()}
                </Box>
            </Box>
            <Modal
                open={billingProfileModalOpen}
                onClose={() => setBillingProfileModalOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Paper style={{ padding: '20px', margin: '20px auto', maxWidth: '80%', borderRadius: '10px' }}>
                    <Typography variant="h6" mb={2}>
                        Sélectionnez un profil de facturation
                    </Typography>
                    <FormControl fullWidth margin="normal">
                        <InputLabel>Profil de facturation</InputLabel>
                        <Select
                            value={selectedBillingProfileId || ''}
                            onChange={(e) => handleBillingProfileChange(e.target.value)}
                        >
                            {billingprofiles.map((profile) => (
                                <MenuItem key={profile.value} value={profile.value}>
                                    {profile.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Button 
                        onClick={handleSaveBillingProfile} 
                        variant="contained" 
                        color="primary" 
                        style={{ marginTop: '20px' }}
                    >
                        Enregistrer
                    </Button>
                </Paper>
            </Modal>
        </Dashboard>
    );
};

export default AccountingDashboard;
 