import React, { useState, useEffect, useContext } from 'react';
import { Modal, Box, Button, Typography, FormControl, InputLabel, Select, MenuItem, TextField, Checkbox, ListItemText } from '@mui/material';
import { HttpRequestContext } from '../../Components/HttpRequestProvider';
import PropertyInputField from '../../Components/PropertyInputField';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const EditNodeModal = ({ open, onClose, onSave, initialData, typeName, nodeType }) => {
  const { sendRequest } = useContext(HttpRequestContext);
  const [formData, setFormData] = useState({
    actionType: initialData?.triggerType || 'création',
    selectedProperties: initialData?.selectedProperties || [],
    waitTime: initialData?.waitTime || '',
    waitUnit: initialData?.waitUnit || 'minute',
    editProperty: initialData?.editProperty || '',
    editValue: initialData?.editValue || '',
    ifElseProperty: initialData?.ifElseProperty || '',
    ifElseValue: initialData?.ifElseValue || '',
  });
  const [crmProperties, setCrmProperties] = useState([]);

  useEffect(() => {
    if (['trigger', 'edit', 'ifElse'].includes(nodeType)) {
      const fetchCrmType = async () => {
        const response = await sendRequest({
          apiPath: `/crm/types/read/${typeName}`,
          type: 'GET',
        });
        setCrmProperties(Object.values(response.properties) || []);
      };

      fetchCrmType();
    }
  }, [typeName, sendRequest, nodeType]);

  useEffect(() => {
    if (initialData) {
      setFormData({
        actionType: initialData?.triggerType || 'création',
        selectedProperties: initialData?.selectedProperties || [],
        waitTime: initialData?.waitTime || '',
        waitUnit: initialData?.waitUnit || 'minute',
        editProperty: initialData?.editProperty || '',
        editValue: initialData?.editValue || '',
        ifElseProperty: initialData?.ifElseProperty || '',
        ifElseValue: initialData?.ifElseValue || '',
      });
    }
  }, [initialData]);

  const handleChange = (name, value) => {
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSave = () => {
    onSave(formData);
    onClose();
  };

  const renderTriggerFields = () => (
    <>
      <FormControl fullWidth sx={{ mt: 2 }}>
        <InputLabel id="action-type-label">Action Type</InputLabel>
        <Select
          labelId="action-type-label"
          id="actionType"
          name="actionType"
          value={formData.actionType}
          onChange={(e) => handleChange(e.target.name, e.target.value)}
        >
          <MenuItem value="création">Création</MenuItem>
          <MenuItem value="modification">Modification</MenuItem>
          <MenuItem value="modification_de_propriété">Modification de propriété</MenuItem>
        </Select>
      </FormControl>
      {formData.actionType === 'modification_de_propriété' && (
        <FormControl fullWidth sx={{ mt: 2 }}>
          <InputLabel id="selected-properties-label">Properties</InputLabel>
          <Select
            labelId="selected-properties-label"
            id="selectedProperties"
            name="selectedProperties"
            multiple
            value={formData.selectedProperties}
            onChange={(e) => handleChange(e.target.name, e.target.value)}
            renderValue={(selected) => selected.join(', ')}
          >
            {crmProperties.map((property) => (
              <MenuItem key={property.property_name} value={property.property_name}>
                <Checkbox checked={formData.selectedProperties.indexOf(property.property_name) > -1} />
                <ListItemText primary={property.display_name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </>
  );

  const renderWaitFields = () => (
    <>
      <TextField
        id="waitTime"
        name="waitTime"
        label="Wait Time"
        type="number"
        fullWidth
        margin="normal"
        value={formData.waitTime}
        onChange={(e) => handleChange(e.target.name, e.target.value)}
      />
      <FormControl fullWidth margin="normal">
        <InputLabel id="wait-unit-label">Unit</InputLabel>
        <Select
          labelId="wait-unit-label"
          id="waitUnit"
          name="waitUnit"
          value={formData.waitUnit}
          onChange={(e) => handleChange(e.target.name, e.target.value)}
        >
          <MenuItem value="minute">Minutes</MenuItem>
          <MenuItem value="hour">Hours</MenuItem>
          <MenuItem value="day">Days</MenuItem>
        </Select>
      </FormControl>
    </>
  );

  const renderEditFields = () => (
    <>
      <FormControl fullWidth sx={{ mt: 2 }}>
        <InputLabel id="edit-property-label">Property</InputLabel>
        <Select
          labelId="edit-property-label"
          id="editProperty"
          name="editProperty"
          value={formData.editProperty}
          onChange={(e) => handleChange(e.target.name, e.target.value)}
        >
          {crmProperties.map((property) => (
            <MenuItem key={property.property_name} value={property.property_name}>
              {property.display_name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {formData.editProperty && (
        <PropertyInputField
          property={crmProperties.find(prop => prop.property_name === formData.editProperty)}
          value={formData.editValue}
          onChange={(name, value) => handleChange('editValue', value)}
        />
      )}
    </>
  );

  const renderIfElseFields = () => (
    <>
      <FormControl fullWidth sx={{ mt: 2 }}>
        <InputLabel id="ifElse-property-label">Property</InputLabel>
        <Select
          labelId="ifElse-property-label"
          id="ifElseProperty"
          name="ifElseProperty"
          value={formData.ifElseProperty}
          onChange={(e) => handleChange(e.target.name, e.target.value)}
        >
          {crmProperties.map((property) => (
            <MenuItem key={property.property_name} value={property.property_name}>
              {property.display_name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {formData.ifElseProperty && (
        <PropertyInputField
          property={crmProperties.find(prop => prop.property_name === formData.ifElseProperty)}
          value={formData.ifElseValue}
          onChange={(name, value) => handleChange('ifElseValue', value)}
        />
      )}
    </>
  );

  return (
    <Modal open={open} onClose={onClose} aria-labelledby="edit-node-title">
      <Box sx={style}>
        <Typography id="edit-node-title" variant="h6" component="h2">
          Edit {nodeType.charAt(0).toUpperCase() + nodeType.slice(1)} Node
        </Typography>
        {nodeType === 'trigger' && renderTriggerFields()}
        {nodeType === 'wait' && renderWaitFields()}
        {nodeType === 'edit' && renderEditFields()}
        {nodeType === 'ifElse' && renderIfElseFields()}
        <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
          <Button onClick={onClose} sx={{ mr: 2 }}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={handleSave}>
            Save
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default EditNodeModal;
