import React, { useState } from 'react';
import Dashboard from '../../Layouts/Dashboard';
import ImportTransactions from './ImportTransactions';
import TransactionSidebar from './TransactionSidebar';
import { Box } from '@mui/material';

const BulkImportTransactions = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const handleSectionChange = (route) => {
        // navigate to the route
        window.location.href = route;
    };

    return (
        <Dashboard dashboardTitle='Bulk Import Transactions' initialFullScreen withSidebar>
            <Box display="flex" height="100%" overflow="hidden">
                <TransactionSidebar
                    isSidebarOpen={isSidebarOpen}
                    setIsSidebarOpen={setIsSidebarOpen}
                    handleSectionChange={handleSectionChange}
                />
                <Box flexGrow={1} p={2} height="100%" overflow="auto">
                    <div className="p-4 w-full">
                        <ImportTransactions />
                    </div>
                </Box>
            </Box>
        </Dashboard>
    );
};

export default BulkImportTransactions;
