import React from 'react';
import { Card, CardContent, Typography, Grid, Box } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import PeopleIcon from '@mui/icons-material/People';
import AssessmentIcon from '@mui/icons-material/Assessment';
import SettingsIcon from '@mui/icons-material/Settings';
import { useNavigate } from 'react-router-dom';

const AdminDashboard = () => {
    const navigate = useNavigate();

    const categories = [
        { title: 'Emailing', description: 'Gérer les templates d\'emails', icon: <EmailIcon sx={{ fontSize: 40 }} />, route: '/admin/emailing' },
        { title: 'Utilisateurs', description: 'Gérer les utilisateurs de la plateforme', icon: <PeopleIcon sx={{ fontSize: 40 }} />, route: '/admin/users' },
        { title: 'Rapports', description: 'Consulter les rapports d\'activités', icon: <AssessmentIcon sx={{ fontSize: 40 }} />, route: '/admin/reports' },
        { title: 'Paramètres', description: 'Configurer les paramètres de l\'application', icon: <SettingsIcon sx={{ fontSize: 40 }} />, route: '/admin/settings' }
    ];

    const handleCategoryClick = (route) => {
        navigate(route);
    };

    return (
        <Box sx={{ flexGrow: 1, p: 4 }}>
            <Grid container spacing={3} justifyContent="center">
                {categories.map((category, index) => (
                    <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
                        <Card
                            variant="outlined"
                            sx={{
                                cursor: 'pointer',
                                transition: 'transform 0.3s, box-shadow 0.3s',
                                '&:hover': {
                                    transform: 'scale(1.05)',
                                    boxShadow: 6
                                },
                                textAlign: 'center'
                            }}
                            onClick={() => handleCategoryClick(category.route)}
                        >
                            <CardContent>
                                <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                                    {category.icon}
                                </Box>
                                <Typography variant="h5" sx={{ mb: 2 }}>
                                    {category.title}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    {category.description}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default AdminDashboard;
