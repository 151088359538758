import React, { useContext, useEffect, useState, useCallback, useMemo } from 'react';
import { ChevronUpDownIcon } from "@heroicons/react/24/outline";
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import { HttpRequestContext } from "./HttpRequestProvider";
import { Icon } from './Icons';
import Modal from './Modal';
import { CurrencyAmountCellRenderer, ImageCellRenderer, TextCellRenderer, BooleanCellRenderer, SelectionCellRenderer, ActionCellRenderer, DropdownCellRenderer, CheckboxCellRenderer, JsonCellRenderer } from './CellRenderers';

const getValueByPath = (obj, path) => {
  return path.split('.').reduce((acc, part) => acc && acc[part], obj);
};

const categorizeColumns = (columns) => {
  if (!Array.isArray(columns)) {
    return {};
  }

  const categories = {};

  columns.forEach(column => {
    const parts = column.name.split('/');
    const mainCategory = parts[0];
    const subCategory = parts.slice(1).join('/');

    if (!categories[mainCategory]) {
      categories[mainCategory] = [];
    }

    categories[mainCategory].push({ id: column.name, ...column, subCategory });
  });

  return categories;
};
const ColumnSelectionModal = ({ isVisible, closeModal, availableColumns, visibleColumns, toggleColumnVisibility }) => {
  const categories = categorizeColumns(availableColumns);
  
  return (
    <Modal isOpen={isVisible} closeModal={closeModal}>
      <Box padding={2}>
        {Object.keys(categories).map(category => (
          <div key={category}>
            <Typography variant="subtitle2" gutterBottom>{category}</Typography>
            {categories[category].map((column) => (
              <label key={column.id} className="flex justify-start items-center text-truncate hover:bg-gray-100 px-4 py-2">
                <input
                  type="checkbox"
                  className="form-checkbox"
                  checked={visibleColumns.has(column.id)}
                  onChange={() => toggleColumnVisibility(column.id)}
                />
                <span className="ml-2">{column.subCategory || column.name}</span>
              </label>
            ))}
          </div>
        ))}
      </Box>
    </Modal>
  );
};

export function SortableTable({ onMassAction, selectionKey, apiEndpoint, Columns, itemsPerPage = 10, initialSortColumn = null, initialSortDirection = 'asc', updateApiEndpoint, updatePropertyKey }) {
  const theme = useTheme();
  const { sendRequest } = useContext(HttpRequestContext);
  const [Rows, setRows] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortOrder, setSortOrder] = useState(initialSortColumn ? { columnId: initialSortColumn, direction: initialSortDirection } : null);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLabelChanged, setIsLabelChanged] = useState(false);
  const [modifiedLabels, setModifiedLabels] = useState({});
  const [refreshTrigger, setRefreshTrigger] = useState(false);
  const [availableColumns, setAvailableColumns] = useState([]); // For columns returned by API
  const [visibleColumns, setVisibleColumns] = useState(new Set(Object.keys(Columns))); // Initially enabled columns
  const [selectedRows, setSelectedRows] = useState(new Set());
  const [expandedCategories, setExpandedCategories] = useState(new Set());
  const showCheckboxes = !!onMassAction;

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const queryParams = new URLSearchParams({
          page: currentPage,
          items_per_page: itemsPerPage,
          ...(sortOrder && { sort: sortOrder.columnId, order: sortOrder.direction })
        });
        const data = await sendRequest({ apiPath: `${apiEndpoint}?${queryParams.toString()}`, type: 'GET' });
        setRows(data.rows);
        setTotalPages(data.totalPages);
        setAvailableColumns(data.columns);
        
        // Only set visibleColumns if they haven't been set yet
        if (visibleColumns.size === 0) {
          setVisibleColumns(new Set(Object.keys(Columns)));
        }
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [sendRequest, apiEndpoint, currentPage, itemsPerPage, sortOrder, refreshTrigger, Columns, visibleColumns.size]);

  const toggleModalVisibility = () => setIsModalOpen(!isModalOpen);

  const toggleColumnVisibility = columnId => setVisibleColumns(prev => new Set(prev.has(columnId) ? Array.from(prev).filter(id => id !== columnId) : [...prev, columnId]));

  const handleSort = columnId => setSortOrder(prev => ({ columnId, direction: prev?.direction === 'asc' ? 'desc' : 'asc' }));

  const handlePageChange = newPage => setCurrentPage(newPage);

  const handleLabelChange = (rowId, newValue) => {
    setIsLabelChanged(true);
    setModifiedLabels(prev => ({ ...prev, [rowId]: newValue }));
  };

  const toggleRowSelection = (row) => {
    if (!showCheckboxes) return;
  
    setSelectedRows((prevSelectedRows) => {
      const newSelectedRows = new Set(prevSelectedRows);
      const rowKey = row[selectionKey];
      if (newSelectedRows.has(rowKey)) {
        newSelectedRows.delete(rowKey);
      } else {
        newSelectedRows.add(rowKey);
      }
      return newSelectedRows;
    });
  };

  const applyChanges = async () => {
    const updates = Object.entries(modifiedLabels).map(([rowId, propertyValues]) => ({
      container_id: rowId,
      property_values: { [updatePropertyKey]: propertyValues },
    }));
  
    try {
      const response = await sendRequest({
        apiPath: updateApiEndpoint,
        type: "POST",
        request: { updates },
        headers: {
          'Content-Type': 'application/json'
        },
      });
  
      if (response.success) {
        setIsLabelChanged(false);
        setModifiedLabels({});
        setRefreshTrigger(!refreshTrigger);
      }
    } catch (error) {
      console.error("Error updating:", error);
    }
  };

  const renderCell = (type, data) => {
    const currentValue = modifiedLabels[data.row.id] || data.value;
    const renderers = { image: ImageCellRenderer, 
      text: TextCellRenderer, string: TextCellRenderer, 
      email: TextCellRenderer, phone: TextCellRenderer, date: TextCellRenderer, 
      status: BooleanCellRenderer, selection: SelectionCellRenderer, 
      currency_amount: CurrencyAmountCellRenderer,
      json: JsonCellRenderer, 
      action: (props) => {
        const { actionCallback, row, tooltipContent, ...rest } = props;
        return (
          <ActionCellRenderer 
            onClickAction={() => actionCallback(row)} 
            tooltipContent={tooltipContent}
            {...rest} 
          />
        );
      },
      label: props => <DropdownCellRenderer {...props} value={currentValue} rowId={props.row.id} onChange={handleLabelChange} />,
      checkbox: (props) => <CheckboxCellRenderer isSelected={selectedRows.has(props.row[selectionKey])} onToggle={() => toggleRowSelection(props.row)} />,
    };
    const Renderer = renderers[type];
    return <Renderer {...data} />
  };

  if (loading && Rows.length === 0) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <>
      <div className="mb-4 flex justify-end">
        <Button variant="contained" color="primary" size="sm" className="uppercase tracking-wider" ripple="true" onClick={toggleModalVisibility} startIcon={isModalOpen ? <Icon iconName="eye-slash" type="fa" /> : <Icon iconName="eye" type="fa" />}>
          {isModalOpen ? 'Masquer menu' : 'Menu colonnes'}
        </Button>
      </div>

      <ColumnSelectionModal 
        isVisible={isModalOpen} 
        closeModal={toggleModalVisibility} 
        availableColumns={availableColumns} 
        visibleColumns={visibleColumns} 
        toggleColumnVisibility={toggleColumnVisibility} 
      />

      <Card variant="outlined" style={{ maxHeight: '600px', overflowY: 'auto' }}> {/* Enable scrolling for the table */}
        <CardContent style={{ padding: "0px" }}>
          <table className="w-full min-w-max table-auto text-left">
            <thead style={{ backgroundColor: theme.palette.primary.light }}>
              <tr>
                {Array.from(visibleColumns).map((columnId) => (
                  <th
                    key={columnId}
                    onClick={() => Columns[columnId]?.sortable && handleSort(columnId)}
                    className="cursor-pointer border-b border-blue-gray-100 bg-blue-gray-50 p-4 transition-colors hover:bg-blue-gray-50"
                  >
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="flex items-center justify-between gap-2 font-normal leading-none opacity-70"
                    >
                      {Columns[columnId]?.name || columnId}
                      {Columns[columnId]?.sortable && (
                        <ChevronUpDownIcon strokeWidth={2} className={`h-4 w-4 ${sortOrder?.columnId === columnId ? 'text-blue-500' : ''}`} />
                      )}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {Rows.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {Array.from(visibleColumns).map((columnId, cellIndex) => {
                    let columnData = Columns[columnId] || { Type: 'text', name: columnId };
                    if (availableColumns.find(col => col.name === columnId)?.type && columnData.Type == "text") {
                      columnData["Type"] = availableColumns.find(col => col.name === columnId)?.type;
                    }

                    const cellData = {
                      value: getValueByPath(row, columnId), // Utilisation de la fonction utilitaire
                      ...columnData, 
                      row,
                    };

                    if (columnData.Type === "label" && availableColumns.find(col => col.name === columnId)?.accepted_values) {
                      cellData["options"] = availableColumns.find(col => col.name === columnId).accepted_values.map(value => ({ label: value, value: value }));
                    }

                    return (
                      <td key={cellIndex} className="p-4">
                        {renderCell(columnData.Type, cellData)}
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </CardContent>
        <CardActions className="flex items-center justify-between border-t border-blue-gray-50 p-4">
          <Typography variant="small" color="blue-gray" className="font-normal">
            Page {currentPage} sur {totalPages}
          </Typography>
          <div className="flex-grow flex justify-center items-center">
            {isLabelChanged && (
              <Button 
                variant="contained" 
                color="primary"
                size="small"
                className="tracking-wider"
                onClick={applyChanges}
              >
                Appliquer
              </Button>
            )}
          </div>

          {showCheckboxes && onMassAction && (
            <div className="flex-grow flex justify-center items-center">
              <Button
                variant="contained"
                color="primary"
                size="small"
                className="tracking-wider"
                onClick={() => onMassAction.action(Array.from(selectedRows))}
              >
                {onMassAction.buttonText}
              </Button>
            </div>
          )}

          <div className="flex gap-2">
            <Button 
              variant="outlined" 
              size="small" 
              color="primary" 
              onClick={() => handlePageChange(Math.max(currentPage - 1, 1))}
              disabled={currentPage === 1}
            >
              Précédent
            </Button>
            <Button 
              variant="outlined" 
              size="small" 
              color="primary" 
              onClick={() => handlePageChange(Math.min(currentPage + 1, totalPages))}
              disabled={currentPage === totalPages}
            >
              Suivant
            </Button>
          </div>
        </CardActions>
      </Card>
    </>
  );
}
