import React, { useState, useEffect, useContext } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { HttpRequestContext } from './HttpRequestProvider';
import PropertyInputField from './PropertyInputField';

const CreateEditContainerInstance = ({ isOpen, onClose, crmType, selectedInstance, onSave, parentDefinitions, parentContainers }) => {
  const { sendRequestWithToast, sendRequest } = useContext(HttpRequestContext);
  const [propertyValues, setPropertyValues] = useState({});
  const [parentValues, setParentValues] = useState({});

  useEffect(() => {
    const initializePropertyValues = () => {
      const initialValues = {};
      for (const [key, property] of Object.entries(crmType.properties)) {
        if (property.type === 'label' && property.accepted_values.length > 0) {
          initialValues[key] = property.accepted_values[0] || ''; // Use the first accepted value as default
        } else {
          initialValues[key] = '';
        }
      }
      return initialValues;
    };

    const fetchContainerDetails = async (containerId) => {
      try {
        const instanceDetails = await sendRequest({
          apiPath: `/crm/container/${containerId}`,
          type: 'GET',
        });

        const initialPropertyValues = {};
        for (const [key, property] of Object.entries(crmType.properties)) {
          initialPropertyValues[key] = instanceDetails.properties[key]?.value || (property.type === 'label' && property.accepted_values.length > 0 ? property.accepted_values[0] : '');
        }

        setPropertyValues(initialPropertyValues);
        setParentValues(instanceDetails.parent_links || {});
      } catch (error) {
        console.error("Error fetching container details:", error);
      }
    };

    if (crmType) {
      if (selectedInstance) {
        fetchContainerDetails(selectedInstance.id);
      } else {
        setPropertyValues(initializePropertyValues());
      }
    }
  }, [crmType, selectedInstance, sendRequest]);

  const handleInputChange = (name, value) => {
    setPropertyValues(prevValues => ({
      ...prevValues,
      [name]: value
    }));
  };

  const handleParentChange = (parentType, value) => {
    setParentValues(prevValues => ({
      ...prevValues,
      [parentType]: value
    }));
  };

  const handleModalSubmit = async () => {
    for (const [key, property] of Object.entries(crmType.properties)) {
      if (property.type === 'label' && !propertyValues[key]) {
        alert(`The field ${property.display_name} is required.`);
        return;
      }
    }

    const endpoint = selectedInstance 
      ? `/crm/container/${selectedInstance.id}`
      : `/crm/instances/${crmType.type_name}/create`;

    const formData = new FormData();
    Object.entries(propertyValues).forEach(([key, value]) => {
      if(value){
        if (value instanceof File) {
          let file = value;
          if (value.name.endsWith('.svg')) {
            file = new File([value], value.name, { type: 'image/svg+xml' });
          }
          formData.append(key, file);
        } else {
          formData.append(key, value);
        }
      }
    });
    formData.append('parent_links', JSON.stringify(parentValues));

    const response = await sendRequestWithToast({
      apiPath: endpoint,
      type: 'POST',
      request: formData,
      successMessage: selectedInstance ? "Updated successfully" : "Création réussie",
      errorMessage: "Erreur lors de la connexion",
    });

    if (response) {
      const newContainerId = response.container_id; // Assume this is the ID of the newly created container
      setPropertyValues({});
      setParentValues({});
      onSave(newContainerId); // Pass the new container ID back to the parent component
    }
  };

  const handleDeleteInstance = async () => {
    if (selectedInstance) {
      const response = await sendRequestWithToast({
        apiPath: `/crm/container/${selectedInstance.id}`,
        type: 'DELETE',
        successMessage: "Deleted successfully",
        errorMessage: "Erreur lors de la suppression",
      });

      if (response) {
        setPropertyValues({});
        setParentValues({});
        onSave();
      }
    }
  };

  const renderParentDropdown = (parentType) => {
    const parentDefinition = parentDefinitions[parentType];
    const parentContainersList = parentContainers[parentType] || [];
    if (!parentDefinition) return null;
    return (
      <FormControl key={parentType} fullWidth margin="normal">
        <InputLabel>{parentDefinition.display_name}</InputLabel>
        <Select
          id={parentType}
          value={parentValues[parentType] || ''}
          onChange={(e) => handleParentChange(parentType, e.target.value)}
          displayEmpty
        >
          {parentContainersList.map(parent => (
            <MenuItem key={parent.id} value={parent.id}>
              {parent?.to_string || parent.id}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>{selectedInstance ? "Modification: " : "Création: "} {crmType.display_name}</DialogTitle>
      <DialogContent>
        {Object.entries(crmType.properties).map(([key, property]) => (
          <PropertyInputField
            key={key}
            property={property}
            value={propertyValues[key]}
            onChange={handleInputChange}
          />
        ))}
        {crmType.parent_links && crmType.parent_links.map(parentType => (
          renderParentDropdown(parentType)
        ))}
      </DialogContent>
      <DialogActions>
        {selectedInstance && (
          <Button onClick={handleDeleteInstance} color="error" variant="contained" style={{ marginRight: 'auto' }}>
            Supprimer
          </Button>
        )}
        <Button onClick={onClose} color="secondary">
          Annuler
        </Button>
        <Button onClick={handleModalSubmit} color="primary" variant="contained">
          {selectedInstance ? "Mettre à Jour" : "Créer"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateEditContainerInstance;
