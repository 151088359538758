import React, {useState,useEffect} from 'react';
import { Icon } from './Icons';
import PhoneInput from 'react-phone-input-2';
import { TagsInput } from "react-tag-input-component";


export function TextInputWithIcon({
    id,
    type,
    placeholder,
    label,
    iconName,
    value,
    onChange,
    name,
    iconType = 'mdi',
    multiline = false, 
    size = 'medium',
}) {
    const inputSizeClasses = {
        small: 'py-1 text-xs',
        medium: 'py-2 text-sm',
        large: 'py-3 text-base',
    };
    const sizeClass = inputSizeClasses[size] || inputSizeClasses.medium;

    return (
        <div className="flex -mx-3">
            <div className="w-full px-3">
                {label && (
                    <label className="text-xs font-semibold px-1">{label}</label>
                )}
                <div className="flex">
                    <div className="icon-style">
                        <Icon iconName={iconName} type={iconType} />
                    </div>
                    {multiline ? (
                        <textarea
                            id={id}
                            value={value}
                            name={name}
                            onChange={onChange}
                            placeholder={placeholder}
                            className={`w-full -ml-10 pl-10 pr-3 rounded-lg border-2 border-gray-200 outline-none focus:border-cyan-900 ${sizeClass}`}
                        />
                    ) : (
                        <input
                            id={id}
                            type={type}
                            value={value}
                            name={name}
                            onChange={onChange}
                            placeholder={placeholder}
                            className={`w-full -ml-10 pl-10 pr-3 rounded-lg border-2 border-gray-200 outline-none focus:border-cyan-900 ${sizeClass}`}
                            autoComplete={name}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}

export function DropdownWithIcon({
    id,
    label,
    iconName,
    value,
    onChange,
    options = [],
    iconType = 'mdi' // default to MDI
}) {
    return (
        <div className="flex -mx-3">
            <div className="w-full px-3">
                {/* Conditional rendering of label */}
                {label && label.trim() !== '' && (
                    <label className="text-xs font-semibold px-1">{label}</label>
                )}
                <div className="flex">
                    <div className="icon-style">
                        <Icon iconName={iconName} type={iconType} />
                    </div>
                    <select
                        id={id}
                        value={value}
                        onChange={onChange}
                        className="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-cyan-900"
                    >
                        {options.map((opt, index) => (
                            <option key={index} value={opt.value}>
                                {opt.label}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
        </div>
    );
}

export function PanelRadioSelect({ 
    options, 
    selectedValue, 
    onChange, 
    label
}) {
    // Default class names
    const defaultLabelClassName = 'border-gray-500 peer-checked:border-cyan-800 peer-checked:shadow-lg peer-checked:text-cyan-800';
    const defaultSpanClassName = 'text-base font-semibold';

    return (

        <div className="w-full px-3">
            <div className="hidden grid grid-cols-2 gap-2"/>
            {label && <label className="block font-semibold mb-2 text-md">{label}</label>}
            <div className={`grid grid-cols-[repeat(auto-fit,minmax(10rem,1fr))] gap-2`}>
                {options.map(option => (
                    <div key={`${option.name}-${option.value}`}>
                        <div className="radio-wrapper">
                            <input 
                                className="peer hidden" 
                                id={option.name} 
                                type="radio" 
                                name={option.name} 
                                value={option.value} 
                                checked={selectedValue === option.value}
                                onChange={() => onChange(option.value)}
                            />
                            <label 
                                className={`radio-label ${option.labelClassName || defaultLabelClassName}`} 
                                htmlFor={option.name}>
                                <span className={option.spanClassName || defaultSpanClassName}>
                                    {option.displayText}
                                </span>
                            </label>
                        </div>
                        {option.subLabel && <label className="text-xs px-1">{option.subLabel}</label>}
                    </div>
                ))}
            </div>
        </div>
    );
}

export function PhoneInputWithIcon({
    id,
    placeholder,
    label,
    iconName,
    value,
    onChange,
    country = 'be',
    iconType = 'mdi',  // default to MDI
    ...otherProps 
}) {
    return (
        <div className="flex -mx-3">
            <div className="w-full px-3">
                <label className="text-xs font-semibold px-1">{label}</label>
                <div className="flex">
                    <div className="icon-style">
                        <Icon iconName={iconName} type={iconType} />
                    </div>
                    <PhoneInput
                        id={id}
                        country={country}
                        value={value}
                        onChange={onChange}
                        placeholder={placeholder}
                        inputClass="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-cyan-900"
                        containerClass="phone-input-wrapper"
                        {...otherProps}
                    />
                </div>
            </div>
        </div>
    );
}

export function TagInputWithIcon({
    id,
    placeholder,
    label,
    value,
    onChange,
    name,
    options = [] // Ajout des options
}) {
    const [tags, setTags] = useState(value || []);

    useEffect(() => {
        onChange(tags);
    }, [tags]);

    return (
        <div className="flex -mx-3">
            <div className="w-full px-3">
                {label && (
                    <label className="text-xs font-semibold px-1 mb-2">{label}</label>
                )}
                <TagsInput
                    id={id}
                    value={tags}
                    onChange={setTags}
                    name={name}
                    placeHolder={placeholder}
                />
                <div className="mt-2">
                    {options.length > 0 && (
                        <ul>
                            {options.map((option, index) => (
                                <li 
                                    key={index} 
                                    onClick={() => setTags([...tags, option])} 
                                    className="cursor-pointer text-blue-500 hover:underline"
                                >
                                    {option}
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
            </div>
        </div>
    );
}

export function FileUploadWithIcon({
    id,
    label,
    iconName,
    onChange,
    iconType = 'mdi',
    accept = '*', 
}) {
    return (
        <div className="flex -mx-3">
            <div className="w-full px-3">
                {label && (
                    <label className="text-xs font-semibold px-1">{label}</label>
                )}
                <div className="flex">
                    <div className="icon-style">
                        <Icon iconName={iconName} type={iconType} />
                    </div>
                    <input
                        id={id}
                        type="file"
                        onChange={onChange}
                        accept={accept}
                        className="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-cyan-900"
                    />
                </div>
            </div>
        </div>
    );
}

export function DateInputWithIcon({
    id,
    placeholder,
    label,
    iconName,
    value,
    onChange,
    name,
    iconType = 'mdi',
    size = 'medium',
}) {
    const inputSizeClasses = {
        small: 'py-1 text-xs',
        medium: 'py-2 text-sm',
        large: 'py-3 text-base',
    };
    const sizeClass = inputSizeClasses[size] || inputSizeClasses.medium;

    return (
        <div className="flex -mx-3">
            <div className="w-full px-3">
                {label && (
                    <label className="text-xs font-semibold px-1">{label}</label>
                )}
                <div className="flex">
                    <div className="icon-style">
                        <Icon iconName={iconName} type={iconType} />
                    </div>
                    <input
                        id={id}
                        type="date"
                        value={value}
                        name={name}
                        onChange={onChange}
                        placeholder={placeholder}
                        className={`w-full -ml-10 pl-10 pr-3 rounded-lg border-2 border-gray-200 outline-none focus:border-cyan-900 ${sizeClass}`}
                        autoComplete={name}
                    />
                </div>
            </div>
        </div>
    );
}


