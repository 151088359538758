import React, { useState, useEffect, useContext } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { HttpRequestContext } from '../../Components/HttpRequestProvider';
import PropertyInputField from '../../Components/PropertyInputField';

const AdminCreateEditContainerInstance = ({ isOpen, onClose, crmType, onSave, parentDefinitions, parentContainers, user }) => {
  const { sendRequestWithToast } = useContext(HttpRequestContext);
  const [propertyValues, setPropertyValues] = useState({});
  const [parentValues, setParentValues] = useState({});

  useEffect(() => {
    const initializePropertyValues = () => {
      const initialValues = {};
      for (const [key, property] of Object.entries(crmType.properties)) {
        if (property.type === 'label' && property.accepted_values.length > 0) {
          initialValues[key] = property.accepted_values[0] || ''; // Use the first accepted value as default
        } else {
          initialValues[key] = '';
        }
      }
      return initialValues;
    };

    setPropertyValues(initializePropertyValues());
  }, [crmType]);

  const handleInputChange = (name, value) => {
    setPropertyValues(prevValues => ({
      ...prevValues,
      [name]: value
    }));
  };

  const handleParentChange = (parentType, value) => {
    setParentValues(prevValues => ({
      ...prevValues,
      [parentType]: value
    }));
  };

  const handleModalSubmit = async () => {
    for (const [key, property] of Object.entries(crmType.properties)) {
      if (property.type === 'label' && !propertyValues[key]) {
        alert(`The field ${property.display_name} is required.`);
        return;
      }
    }

    const formData = new FormData();
    Object.entries(propertyValues).forEach(([key, value]) => {
      if (value) {
        if (value instanceof File) {
          formData.append(key, value);
        } else {
          formData.append(key, value);
        }
      }
    });
    formData.append('parent_links', JSON.stringify(parentValues));
    formData.append('user_email', user.email);
    formData.append('type_name', crmType.type_name);

    const response = await sendRequestWithToast({
      apiPath: '/admin/crm/create_container',
      type: 'POST',
      request: formData,
      successMessage: "Création réussie",
      errorMessage: "Erreur lors de la création",
    });

    if (response) {
      const newContainerId = response.container_id; // Assume this is the ID of the newly created container
      setPropertyValues({});
      setParentValues({});
      onSave(newContainerId); // Pass the new container ID back to the parent component
    }
  };

  const renderParentDropdown = (parentType) => {
    const parentDefinition = parentDefinitions[parentType];
    const parentContainersList = parentContainers[parentType] || [];
    if (!parentDefinition) return null;
    return (
      <FormControl key={parentType} fullWidth margin="normal">
        <InputLabel>{parentDefinition.display_name}</InputLabel>
        <Select
          id={parentType}
          value={parentValues[parentType] || ''}
          onChange={(e) => handleParentChange(parentType, e.target.value)}
          displayEmpty
        >
          {parentContainersList.map(parent => (
            <MenuItem key={parent.id} value={parent.id}>
              {parent?.to_string || parent.id}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Création: {crmType.display_name}</DialogTitle>
      <DialogContent>
        {Object.entries(crmType.properties).map(([key, property]) => (
          <PropertyInputField
            key={key}
            property={property}
            value={propertyValues[key]}
            onChange={handleInputChange}
          />
        ))}
        {crmType.parent_links && crmType.parent_links.map(parentType => (
          renderParentDropdown(parentType)
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Annuler
        </Button>
        <Button onClick={handleModalSubmit} color="primary" variant="contained">
          Créer
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AdminCreateEditContainerInstance;
