import React, { useState, useEffect, useContext } from 'react';
import { Box, Card, CardContent, Typography, Table, TableBody, TableCell, TableHead, TableRow, Button, Modal, Paper, FormControl, InputLabel, Select, MenuItem, TextField } from '@mui/material';
import { HttpRequestContext } from '../../Components/HttpRequestProvider';
import Dashboard from '../../Layouts/Dashboard';
import TransactionSidebar from './TransactionSidebar';

const TransactionInvoiceMatching = () => {
    const { sendRequest, sendRequestWithToast } = useContext(HttpRequestContext);
    const [transactions, setTransactions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [selectedTransactionId, setSelectedTransactionId] = useState('');
    const [filteredTransactions, setFilteredTransactions] = useState([]);
    const [invoices, setInvoices] = useState([]);
    const [selectedInvoiceId, setSelectedInvoiceId] = useState('');

    const [billingProfiles, setBillingProfiles] = useState([]);
    const [selectedBillingProfileId, setSelectedBillingProfileId] = useState('');
    const [billingProfileModalOpen, setBillingProfileModalOpen] = useState(false);
    
    const [vatRate, setVatRate] = useState('');
    const [receiptModalOpen, setReceiptModalOpen] = useState(false);
    const [selectedTransactionForReceipt, setSelectedTransactionForReceipt] = useState(null);
    
    const [uploadModalOpen, setUploadModalOpen] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [transactionForUpload, setTransactionForUpload] = useState(null);
    
    const handleOpenReceiptModal = (transaction) => {
        setSelectedTransactionForReceipt(transaction);
        setReceiptModalOpen(true);
    };

    const handleVatRateChange = (event) => {
        setVatRate(event.target.value);
    };

    const handleSectionChange = (route) => {
        // navigate to the route
        window.location.href = route;
    };
    
    const handleCreateReceipt = async () => {
        if (!selectedTransactionForReceipt || !vatRate) {
            alert('Veuillez sélectionner un taux de TVA.');
            return;
        }
    
        const transaction = selectedTransactionForReceipt;
        const vat = parseFloat(vatRate) / 100;
        const prixUnitaire = parseFloat((transaction.amount.amount / (1 + vat)).toFixed(2));
    
        const lineItem = {
            quantity: 1,
            description: transaction["Client"],
            price: prixUnitaire,
            tax: vat
        };
    
        const recuDate = new Date(transaction.transaction_date);
        const dueDate = new Date(recuDate);
        dueDate.setDate(recuDate.getDate() + 30);
    
        const receiptData = {
            billingprofile: selectedBillingProfileId,
            customer: transaction["Client/id"],
            recuDate: recuDate.toISOString().split('T')[0],
            dueDate: dueDate.toISOString().split('T')[0],
            recuStatus: "Payé",
            lineItems: [lineItem]
        };
    
        try {
            const responseData = await sendRequestWithToast({
                apiPath: 'recu/create',
                request: receiptData,
                type: 'POST',
                successMessage: 'Reçu créé avec succès!',
                errorMessage: 'Erreur lors de la création du reçu.',
                headers: { 'Content-Type': 'application/json' }
            });

            const receiptId = responseData["recuId"]
            await sendRequest({
                apiPath: `/crm/container/${transaction.id}/add-parent`,
                request: {
                    parent_container_id: receiptId,
                },
                type: 'POST',
                headers: { 'Content-Type': 'application/json' }
            });

            setReceiptModalOpen(false);
            fetchTransactions();
        } catch (error) {
            console.error('Erreur lors de la création du reçu:', error);
            alert('Une erreur est survenue lors de la création du reçu.');
        }
    };

    const fetchBillingProfiles = async () => {
        try {
            const response = await sendRequest({
                apiPath: 'crm/instances/billingprofile/list?items_per_page=-1',
                type: 'GET',
            });
    
            const profiles = response.rows || [];
            setBillingProfiles(profiles);
    
            const storedBillingProfileId = localStorage.getItem('selectedBillingProfileId');
            if (!storedBillingProfileId || !profiles.some(profile => profile.id === storedBillingProfileId)) {
                setBillingProfileModalOpen(true);
            } else {
                setSelectedBillingProfileId(storedBillingProfileId);
            }
        } catch (error) {
            console.error("Erreur lors de la récupération des profils de facturation:", error);
        }
    };
    
    useEffect(() => {
        fetchBillingProfiles();
    }, [sendRequest]);

    const handleBillingProfileChange = (profileId) => {
        setSelectedBillingProfileId(profileId);
        localStorage.setItem('selectedBillingProfileId', profileId);
    };
    
    const handleSaveBillingProfile = () => {
        if (!selectedBillingProfileId) {
            alert('Veuillez sélectionner un profil de facturation.');
            return;
        }
        localStorage.setItem('selectedBillingProfileId', selectedBillingProfileId);
        setBillingProfileModalOpen(false);
        fetchTransactions();
    };
    

    const fetchTransactions = async () => {
        if (!selectedBillingProfileId) {
            setBillingProfileModalOpen(true);
            return;
        }
    
        try {
            const response = await sendRequest({
                apiPath: 'crm/instances/transaction/list?items_per_page=-1',
                type: 'GET',
            });
    
            const filteredTransactions = (response.rows || []).filter(transaction => {
                return (
                    (transaction.Client || transaction.Fournisseur) &&
                    transaction.status !== "ignored" &&
                    !transaction.Facture &&
                    !transaction['Note de Crédit'] &&
                    !transaction.PdfContainer &&
                    !transaction['Reçu de Caisse']
                );
            });
    
            setTransactions(filteredTransactions);
            setLoading(false);
        } catch (error) {
            console.error("Erreur lors de la récupération des transactions:", error);
            setLoading(false);
        }
    };
    
    useEffect(() => {
        if (selectedBillingProfileId) {
            fetchTransactions();
        }
    }, [selectedBillingProfileId, sendRequest]);

    const handleOpenModal = async (transactionId) => {
        setSelectedTransactionId(transactionId);
        const filtered = transactions.filter(transaction => transaction.id === transactionId);
        setFilteredTransactions(filtered);
    
        try {
            const response = await sendRequest({
                apiPath: 'crm/instances/facture/list?items_per_page=-1',
                type: 'GET',
            });
    
            const filteredInvoices = (response.rows || []).filter(invoice => 
                invoice["Client/id"] === filtered[0]["Client/id"] &&
                (!invoice["childs/transaction"] || invoice["childs/transaction"].length === 0)
            );
    
            setInvoices(filteredInvoices);
        } catch (error) {
            console.error('Erreur lors de la récupération des factures:', error);
        }
    
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setSelectedTransactionId('');
        setFilteredTransactions([]);
        setSelectedInvoiceId('');
    };

    const handleInvoiceChange = (invoiceId) => {
        setSelectedInvoiceId(invoiceId);
    };



    const handleSave = async () => {
        try {
            if (!selectedInvoiceId) {
                alert('Veuillez sélectionner une facture.');
                return;
            }
    
            await sendRequestWithToast({
                apiPath: `/crm/container/${selectedTransactionId}/add-parent`,
                request: {
                    parent_container_id: selectedInvoiceId,
                },
                type: 'POST',
                successMessage: 'Règle de correspondance enregistrée avec succès!',
                errorMessage: 'Erreur lors de l\'enregistrement de la règle de correspondance.',
                headers: { 'Content-Type': 'application/json' }
            });
    
            handleCloseModal();
        } catch (error) {
            console.error('Erreur lors de l\'enregistrement de la règle de correspondance:', error);
            alert('Une erreur est survenue lors de l\'enregistrement de la règle de correspondance.');
        }
    };
    
    const handleIgnoreTransaction = async (transactionId) => {
        try {
            await sendRequestWithToast({
                apiPath: 'transaction/ignorer',
                request: { transaction_id: transactionId },
                type: 'POST',
                successMessage: 'Transaction ignorée avec succès!',
                errorMessage: 'Erreur lors de l\'ignorance de la transaction.',
                headers: { 'Content-Type': 'application/json' }
            });

            fetchTransactions(); // Rafraîchir la liste des transactions
        } catch (error) {
            console.error('Erreur lors de l\'ignorance de la transaction:', error);
            alert('Une erreur est survenue lors de l\'ignorance de la transaction.');
        }
    };

    const handleCreateCreditNote = async (transactionId) => {
        try {
            await sendRequestWithToast({
                apiPath: 'transaction/associer-nouvelle-note-credit',
                request: { transaction_id: transactionId },
                type: 'POST',
                successMessage: 'Note de crédit créée avec succès!',
                errorMessage: 'Erreur lors de la création de la note de crédit.',
                headers: { 'Content-Type': 'application/json' }
            });

            fetchTransactions(); // Rafraîchir la liste des transactions
        } catch (error) {
            console.error('Erreur lors de la création de la note de crédit:', error);
            alert('Une erreur est survenue lors de la création de la note de crédit.');
        }
    };

    const handleAcknowledgeTransaction = async (transactionId) => {
        try {
            const requestData = {
            };
    
            const responseData = await sendRequest({
                apiPath: '/crm/instances/pdfcontainer/create',
                request: requestData,
                type: 'POST',
                headers: { 'Content-Type': 'application/json' }
            });
            const pdfcontainerId = responseData["container_id"]
            
            await sendRequestWithToast({
                apiPath: `/crm/container/${transactionId}/add-parent`,
                request: {
                    parent_container_id: pdfcontainerId,
                },
                type: 'POST',
                successMessage: 'Transaction approuvée!',
                errorMessage: 'Erreur lors de l\'approbation de la transaction.',
                headers: { 'Content-Type': 'application/json' }
            });
            
    
            fetchTransactions(); // Rafraîchir la liste des transactions
        } catch (error) {
            console.error('Erreur lors de la reconnaissance de la transaction:', error);
            alert('Une erreur est survenue lors de la reconnaissance de la transaction.');
        }
    };

    const handleOpenUploadModal = async (transaction) => {
        setTransactionForUpload(transaction);
        const filtered = transactions.filter(t => t.id === transaction.id);
        setFilteredTransactions(filtered);
        setUploadModalOpen(true);
    };    

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleUploadFacture = async () => {
        if (!selectedFile) {
            alert('Veuillez sélectionner un fichier.');
            return;
        }

        try {
            const formData = new FormData();
            formData.append('pdf_path', selectedFile);
            formData.append('name', selectedFile.name);

            const responseData = await sendRequest({
                apiPath: '/crm/instances/pdfcontainer/create',
                request: formData,
                type: 'POST',
                headers: { 'Content-Type': 'multipart/form-data' }
            });

            const pdfcontainerId = responseData["container_id"];
            
            await sendRequestWithToast({
                apiPath: `/crm/container/${transactionForUpload.id}/add-parent`,
                request: {
                    parent_container_id: pdfcontainerId,
                },
                type: 'POST',
                successMessage: 'Facture uploadée avec succès!',
                errorMessage: 'Erreur lors de l\'upload de la facture.',
                headers: { 'Content-Type': 'application/json' }
            });

            setUploadModalOpen(false);
            fetchTransactions(); // Refresh the transactions list
        } catch (error) {
            console.error('Erreur lors de l\'upload de la facture:', error);
            alert('Une erreur est survenue lors de l\'upload de la facture.');
        }
    };

    return (
        <Dashboard dashboardTitle="Matching Factures" initialFullScreen withSidebar>
            <Box display="flex" height="100%" overflow="hidden">
                <TransactionSidebar
                    isSidebarOpen={isSidebarOpen}
                    setIsSidebarOpen={setIsSidebarOpen}
                    handleSectionChange={handleSectionChange}
                />
                <Box flexGrow={1} p={2} height="100%" overflow="auto">
                    <Card>
                        <CardContent>
                        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                            <Typography variant="h5">
                                Matching Factures
                            </Typography>
                        </Box>

                        {loading ? (
                            <Typography>Chargement...</Typography>
                        ) : (
                            <>
                                <Typography variant="h6" mt={3} mb={2}>
                                    Clients
                                </Typography>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Client</TableCell>
                                            <TableCell>Montant</TableCell>
                                            <TableCell>Date</TableCell>
                                            <TableCell>Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {transactions.filter(transaction => transaction.Client).map((transaction, index) => (
                                            <TableRow key={index}>
                                                <TableCell>{transaction["Client/Prénom du Client"]} {transaction["Client/Nom du Client"]} - {transaction["Client/Nom de société"]}</TableCell>
                                                <TableCell>{transaction.amount.amount}</TableCell>
                                                <TableCell>{new Date(transaction.transaction_date).toLocaleDateString()}</TableCell>
                                                <TableCell>
                                                        <>
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                onClick={() => handleOpenModal(transaction.id)}
                                                            >
                                                                Associer Facture
                                                            </Button>
                                                            {transaction.amount.amount > 0 ? (
                                                                <Button
                                                                variant="contained"
                                                                color="secondary"
                                                                onClick={() => handleOpenReceiptModal(transaction)}
                                                                style={{ marginLeft: '10px' }}
                                                            >
                                                                Créer Reçu
                                                            </Button>
                                                            ) : (
            
                                                                <Button
                                                                    variant="contained"
                                                                    color="secondary"
                                                                    onClick={() => handleCreateCreditNote(transaction.id)}
                                                                    style={{ marginLeft: '10px' }}
                                                                >
                                                                    Créer Note de Crédit
                                                                </Button>
                                                            )}
                                                        </>
                                                    <Button
                                                        variant="contained"
                                                        color="warning"
                                                        onClick={() => handleIgnoreTransaction(transaction.id)}
                                                        style={{ marginLeft: '10px' }}
                                                    >
                                                        Ignorer
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>

                                <Typography variant="h6" mt={3} mb={2}>
                                    Fournisseurs
                                </Typography>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Fournisseur</TableCell>
                                            <TableCell>Montant</TableCell>
                                            <TableCell>Date</TableCell>
                                            <TableCell>Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {transactions.filter(transaction => transaction.Fournisseur).map((transaction, index) => (
                                            <TableRow key={index}>
                                                <TableCell>{transaction["Fournisseur"]}</TableCell>
                                                <TableCell>{transaction.amount.amount}</TableCell>
                                                <TableCell>{new Date(transaction.transaction_date).toLocaleDateString()}</TableCell>
                                                <TableCell>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={() => handleOpenUploadModal(transaction)}
                                                    >
                                                        Upload Facture
                                                    </Button>
                                                    <Button
                                                        variant="contained"
                                                        color="info"
                                                        onClick={() => handleAcknowledgeTransaction(transaction.id)}
                                                        style={{ marginLeft: '10px' }}
                                                    >
                                                        Approuver Sans Factures
                                                    </Button>
                                                    <Button
                                                        variant="contained"
                                                        color="warning"
                                                        onClick={() => handleIgnoreTransaction(transaction.id)}
                                                        style={{ marginLeft: '10px' }}
                                                    >
                                                        Ignorer
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </>
                        )}
                    </CardContent>
                </Card>
                </Box>

                <Modal
                    open={openModal}
                    onClose={handleCloseModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Paper style={{ padding: '20px', margin: '20px auto', maxWidth: '80%', maxHeight: '90vh', overflow: 'auto' }}>
                        <Typography variant="h6" mb={2}>
                            Transactions pour {selectedTransactionId}
                        </Typography>
                        <Box mb={2}>
                            <FormControl fullWidth margin="normal">
                                <InputLabel>Facture</InputLabel>
                                <Select
                                    value={selectedInvoiceId || ''}
                                    onChange={(e) => handleInvoiceChange(e.target.value)}
                                >
                                    {invoices.map((invoice) => (
                                        <MenuItem key={invoice.id} value={invoice.id}>
                                            {new Date(invoice.date_emission).toLocaleDateString()} : {invoice.to_string} --  {invoice["PdfContainer/content JSON"]["total_tvac"]} EUR
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>

                        <Button 
                            onClick={handleSave} 
                            variant="contained" 
                            color="primary" 
                            style={{ marginTop: '20px' }}
                        >
                            Enregistrer
                        </Button>

                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>ID</TableCell>
                                    <TableCell>Montant</TableCell>
                                    <TableCell>Date</TableCell>
                                    <TableCell>Description</TableCell>
                                    <TableCell>Code de devise</TableCell>
                                    <TableCell>Données brutes de la source</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredTransactions.map((transaction) => (
                                    <TableRow key={transaction.id}>
                                        <TableCell>{transaction.id}</TableCell>
                                        <TableCell>{transaction.amount.amount}</TableCell>
                                        <TableCell>{new Date(transaction.transaction_date).toLocaleDateString()}</TableCell>
                                        <TableCell>{transaction.to_string}</TableCell>
                                        <TableCell>{transaction.currency_code}</TableCell>
                                        <TableCell>{JSON.stringify(transaction.raw_source_data)}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        <Button onClick={handleCloseModal} variant="contained" color="secondary" style={{ marginTop: '20px', marginLeft: '10px' }}>
                            Fermer
                        </Button>
                    </Paper>
                </Modal>

                <Modal
                    open={billingProfileModalOpen}
                    onClose={() => setBillingProfileModalOpen(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Paper style={{ padding: '20px', margin: '20px auto', maxWidth: '80%' }}>
                        <Typography variant="h6" mb={2}>
                            Sélectionnez un profil de facturation
                        </Typography>
                        <FormControl fullWidth margin="normal">
                            <InputLabel>Profil de facturation</InputLabel>
                            <Select
                                value={selectedBillingProfileId || ''}
                                onChange={(e) => handleBillingProfileChange(e.target.value)}
                            >
                                {billingProfiles.map((profile) => (
                                    <MenuItem key={profile.id} value={profile.id}>
                                        {profile.to_string}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Button 
                            onClick={handleSaveBillingProfile} 
                            variant="contained" 
                            color="primary" 
                            style={{ marginTop: '20px' }}
                        >
                            Enregistrer
                        </Button>
                    </Paper>
                </Modal>

                <Modal
                    open={receiptModalOpen}
                    onClose={() => setReceiptModalOpen(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Paper style={{ padding: '20px', margin: '20px auto', maxWidth: '80%' }}>
                        <Typography variant="h6" mb={2}>
                            Sélectionnez le taux de TVA
                        </Typography>
                        <FormControl fullWidth margin="normal">
                            <InputLabel>Taux de TVA</InputLabel>
                            <Select
                                value={vatRate || ''}
                                onChange={handleVatRateChange}
                            >
                                <MenuItem value={0}>0%</MenuItem>
                                <MenuItem value={6}>6%</MenuItem>
                                <MenuItem value={12}>12%</MenuItem>
                                <MenuItem value={21}>21%</MenuItem>
                            </Select>
                        </FormControl>
                        <Button 
                            onClick={handleCreateReceipt} 
                            variant="contained" 
                            color="primary" 
                            style={{ marginTop: '20px' }}
                        >
                            Créer Reçu
                        </Button>
                    </Paper>
                </Modal>

                <Modal
                    open={uploadModalOpen}
                    onClose={() => setUploadModalOpen(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Paper style={{ padding: '20px', margin: '20px auto', maxWidth: '80%', maxHeight: '90vh', overflow: 'auto' }}>
                        <Typography variant="h6" mb={2}>
                            Upload Facture
                        </Typography>
                        
                        <Box mb={2}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>ID</TableCell>
                                        <TableCell>Montant</TableCell>
                                        <TableCell>Date</TableCell>
                                        <TableCell>Description</TableCell>
                                        <TableCell>Code de devise</TableCell>
                                        <TableCell>Données brutes de la source</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {filteredTransactions.map((transaction) => (
                                        <TableRow key={transaction.id}>
                                            <TableCell>{transaction.id}</TableCell>
                                            <TableCell>{transaction.amount.amount}</TableCell>
                                            <TableCell>{new Date(transaction.transaction_date).toLocaleDateString()}</TableCell>
                                            <TableCell>{transaction.to_string}</TableCell>
                                            <TableCell>{transaction.currency_code}</TableCell>
                                            <TableCell>{JSON.stringify(transaction.raw_source_data)}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>

                        <FormControl fullWidth margin="normal">
                            <input
                                type="file"
                                onChange={handleFileChange}
                                accept="application/pdf"
                            />
                        </FormControl>
                        <Button 
                            onClick={handleUploadFacture} 
                            variant="contained" 
                            color="primary" 
                            style={{ marginTop: '20px' }}
                        >
                            Upload
                        </Button>
                        <Button 
                            onClick={() => setUploadModalOpen(false)} 
                            variant="contained" 
                            color="secondary" 
                            style={{ marginTop: '20px', marginLeft: '10px' }}
                        >
                            Fermer
                        </Button>
                    </Paper>
                </Modal>
            </Box>
        </Dashboard>
    );
};

export default TransactionInvoiceMatching;
