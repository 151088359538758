import React, { useState } from 'react';
import ResponsiveNavbar from './ResponsiveNavbar';
import AdminSidebar from '../Components/Admin/AdminSidebar';
import { Box } from '@mui/material';

const Dashboard = ({ children, dashboardTitle, initialFullScreen = false, withSidebar = false }) => {
    const [fullScreen, setFullScreen] = useState(initialFullScreen);
    const [sidebarOpen, setSidebarOpen] = useState(!withSidebar);
    const navbarHeight = 64; // Adjust based on your navbar's height (default height for AppBar is 64px)

    const toggleFullScreen = () => {
        if (!initialFullScreen) {
            setFullScreen(prevState => !prevState);
        }
    };

    return (
        <div className="wrapper">
            <div className="min-h-screen w-full bg-[#f8fafc] relative flex overflow-auto">
                <Box sx={{ display: 'flex', width: '100%' }}>
                    <ResponsiveNavbar toggleFullScreen={toggleFullScreen} disableFullScreenButton={initialFullScreen} />
                    {withSidebar && <AdminSidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />}
                    <Box
                        component="main"
                        sx={{
                            flexGrow: 1,
                            transition: 'margin-left 0.3s',
                            marginLeft: withSidebar ? (sidebarOpen ? '240px' : '60px') : '0px',
                            paddingTop: `${navbarHeight}px`,
                            backgroundImage: !fullScreen ? "url('/dist/img/baseapp_background.svg')" : null,
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'between',
                            minHeight: '100vh'
                        }}
                    >
                        <div className={`h-full w-full m-4 flex-wrap items-start justify-start rounded-tl grid-flow-col auto-cols-max gap-4 mx-auto ${!fullScreen ? 'max-w-6xl' : ''}`}>
                            {children}
                        </div>
                    </Box>
                </Box>
            </div>
        </div>
    );
}

export default Dashboard;
 