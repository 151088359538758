import React from 'react';

function Modal({ isOpen, closeModal, children }) {
    const handleBackgroundClick = (event) => {
        // Check if the click was directly on the modal's background
        if (event.target.id === "genericModal") {
            closeModal();
        }
    };

    if (!isOpen) return null;

    return (
        <div id="genericModal" className={`modal fixed top-0 left-0 w-full h-full flex items-center justify-center`} onClick={handleBackgroundClick}>
            <div className="modal-content bg-white w-full h-full sm:w-3/4 sm:h-auto sm:rounded-md shadow-lg relative overflow-hidden sm:items-start sm:mt-10 mt-25">
                {children}
            </div>
        </div>
    );
}

export default Modal;
