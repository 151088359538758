import React, { useState, useEffect, useContext } from 'react';
import { Box, Card, CardContent, Typography, Table, TableBody, TableCell, TableHead, TableRow, Button, Modal, Paper, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { ContainerListReader } from '../../Components/ContainerListReader';
import Dashboard from '../../Layouts/Dashboard';
import CreateEditContainerInstance from '../../Components/CreateEditContainerInstance';
import TransactionSidebar from './TransactionSidebar';
import { HttpRequestContext } from '../../Components/HttpRequestProvider';
import { resolveValueObject } from '../../Components/ValueObjects';

const TransactionMatching = () => {
    const { sendRequest, sendRequestWithToast } = useContext(HttpRequestContext);
    const { fetchData } = ContainerListReader();
    const [transactions, setTransactions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [openModal, setOpenModal] = useState(false);
    const [selectedThirdPartyId, setSelectedThirdPartyId] = useState('');
    const [filteredTransactions, setFilteredTransactions] = useState([]);
    const [parentTypes, setParentTypes] = useState([]);
    const [parentContainers, setParentContainers] = useState({});
    const [selectedParentTypes, setSelectedParentTypes] = useState({});
    const [selectedContainers, setSelectedContainers] = useState({});
    const [isCreateEditModalOpen, setIsCreateEditModalOpen] = useState(false);
    const [currentCrmType, setCurrentCrmType] = useState(null);
    const [shouldSave, setShouldSave] = useState(false);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const [matchingRules, setMatchingRules] = useState([]);
    const [isMatchingRulesModalOpen, setIsMatchingRulesModalOpen] = useState(false);


    const fetchTransactions = async () => {
        try {
            const response = await fetchData('crm/instances/transaction/list', { items_per_page: -1 });

            const filteredTransactions = (response.rows || []).filter(transaction => {
                return !(
                    transaction.Client ||
                    transaction.Fournisseur ||
                    transaction.status.value === "ignored"
                );
            });

            setTransactions(filteredTransactions);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching transactions:", error);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchTransactions();
    }, []);

    useEffect(() => {
        const fetchParentTypes = async () => {
            try {
                const response = await sendRequest({
                    apiPath: 'crm/types/read/transaction',
                    type: 'GET',
                });
                setParentTypes(response.parent_links || []);
            } catch (error) {
                console.error("Error fetching parent types:", error);
            }
        };

        fetchParentTypes();
    }, [sendRequest]);

    const handleParentTypeChange = async (thirdPartyId, parentType) => {
        setSelectedParentTypes(prev => ({
            ...prev,
            [thirdPartyId]: parentType
        }));

        try {
            const response = await fetchData(`crm/instances/${parentType}/list`);
            setParentContainers(prev => ({
                ...prev,
                [thirdPartyId]: response.rows || []
            }));
        } catch (error) {
            console.error(`Error fetching containers for parent type ${parentType}:`, error);
        }
    };

    const handleContainerChange = (thirdPartyId, containerId) => {
        setSelectedContainers(prev => ({
            ...prev,
            [thirdPartyId]: containerId
        }));
    };

    const handleOpenModal = (thirdPartyId) => {
        setSelectedThirdPartyId(thirdPartyId);
        const filtered = transactions.filter(transaction => transaction.third_party_id.value === thirdPartyId);
        setFilteredTransactions(filtered);
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setSelectedThirdPartyId('');
        setFilteredTransactions([]);
    };

    const handleSave = async () => {
        try {
            const parentType = selectedParentTypes[selectedThirdPartyId];
            const parentId = selectedContainers[selectedThirdPartyId];

            if (!parentType || !parentId) {
                alert('Please select both a Type de Tiers and a Tierce Partie Associée.');
                return;
            }

            await sendRequestWithToast({
                apiPath: 'transaction/matching-rule/third-party/save',
                request: {
                    third_party_id: selectedThirdPartyId,
                    parent_type: parentType,
                    container_id: parentId,
                },
                type: 'POST',
                successMessage: 'Matching rule saved successfully!',
                errorMessage: 'Error saving matching rule.',
                headers: { 'Content-Type': 'application/json' }
            });

            handleCloseModal();
            fetchTransactions();
        } catch (error) {
            console.error('Error saving matching rule:', error);
            alert('An error occurred while saving the matching rule.');
        }
    };

    const handleApplyMatchingRules = async () => {
        try {
            await sendRequestWithToast({
                apiPath: 'transaction/matching-rule/third-party/apply',
                request: {},
                type: 'POST',
                successMessage: 'Matching rules applied successfully!',
                errorMessage: 'Error applying matching rules.',
                headers: { 'Content-Type': 'application/json' }
            });

            fetchTransactions();
        } catch (error) {
            console.error('Error applying matching rules:', error);
            alert('An error occurred while applying the matching rules.');
        }
    };

    const handleCreateNewContainer = async () => {
        const parentType = selectedParentTypes[selectedThirdPartyId];
        try {
            const response = await sendRequest({
                apiPath: `crm/types/read/${parentType}`,
                type: 'GET',
            });
            setCurrentCrmType(response);
            setIsCreateEditModalOpen(true);
        } catch (error) {
            console.error('Error fetching CRM type:', error);
        }
    };

    const handleSaveNewContainer = async (newContainerId) => {
        setIsCreateEditModalOpen(false);

        // Fetch the updated list of parent containers
        const parentType = selectedParentTypes[selectedThirdPartyId];
        try {
            const response = await fetchData(`crm/instances/${parentType}/list`);

            const updatedContainers = response.rows || [];
            setParentContainers(prev => ({
                ...prev,
                [selectedThirdPartyId]: updatedContainers,
            }));

            // Select the new container in the dropdown and then trigger the save
            setSelectedContainers(prev => ({
                ...prev,
                [selectedThirdPartyId]: newContainerId,
            }));

            // Trigger save action
            setShouldSave(true);
        } catch (error) {
            console.error('Error fetching updated containers:', error);
        }

        fetchTransactions(); // Refresh transactions list
    };

    useEffect(() => {
        if (shouldSave) {
            handleSave();
            setShouldSave(false); // Reset the trigger
        }
    }, [shouldSave, selectedContainers]); // Ensure selectedContainers is up-to-date before saving

    const uniqueThirdPartyIds = transactions.reduce((acc, transaction) => {
        const thirdPartyId = transaction.third_party_id.value;
        const amount = transaction.amount.value.amount;

        if (!acc[thirdPartyId]) {
            acc[thirdPartyId] = { expenses: 0, income: 0, transactions: [], thirdPartyId:thirdPartyId };
        }
        if (amount < 0) {
            acc[thirdPartyId].expenses += 1;
        } else {
            acc[thirdPartyId].income += 1;
        }
        acc[thirdPartyId].transactions.push(transaction);
        return acc;
    }, {});

    const handleIgnoreThirdParty = async (thirdPartyId) => {
        try {
            await sendRequestWithToast({
                apiPath: 'transaction/matching-rule/third-party/ignore',
                request: {
                    third_party_id: thirdPartyId
                },
                type: 'POST',
                successMessage: 'Ignore rule saved successfully!',
                errorMessage: 'Error saving ignore rule.',
                headers: { 'Content-Type': 'application/json' }
            });

            // Refresh the transactions list
            fetchTransactions();
        } catch (error) {
            console.error('Error saving ignore rule:', error);
            alert('An error occurred while saving the ignore rule.');
        }
    };

    const handleSectionChange = (route) => {
        // navigate to the route
        window.location.href = route;
    };



    // Matching rules
    const fetchMatchingRules = async () => {
        try {
            const response = await sendRequest({
                apiPath: '/transaction/matching-rule/third-party/all',
                type: 'GET',
            });
            setMatchingRules(response);
        } catch (error) {
            console.error('Error fetching matching rules:', error);
        }
    };

    const handleShowMatchingRules = () => {
        fetchMatchingRules();
        setIsMatchingRulesModalOpen(true);
    };

    const handleCloseMatchingRulesModal = () => {
        setIsMatchingRulesModalOpen(false);
    };

    const handleDeleteRule = async (ruleId) => {
        try {
            await sendRequestWithToast({
                apiPath: `transaction/matching-rule/delete/${ruleId}`,
                type: 'DELETE',
                successMessage: 'Rule deleted successfully!',
                errorMessage: 'Error deleting rule.',
            });
            fetchMatchingRules(); // Refresh the rules list
        } catch (error) {
            console.error('Error deleting rule:', error);
        }
    };




    return (
        <Dashboard dashboardTitle="Transaction Matching" withSidebar>
            <Box display="flex" height="100%" overflow="hidden">
                <TransactionSidebar
                    isSidebarOpen={isSidebarOpen}
                    setIsSidebarOpen={setIsSidebarOpen}
                    handleSectionChange={handleSectionChange}
                />
                <Box flexGrow={1} p={2} height="100%" overflow="auto">
                    <Card>
                        <CardContent>
                            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                                <Typography variant="h5">
                                    Transactions sans Contreparties
                                </Typography>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={handleApplyMatchingRules}
                                >
                                    Apply Matching Rules
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleShowMatchingRules}
                                >
                                    Show Matching Rules
                                </Button>
                            </Box>

                            {loading ? (
                                <Typography>Loading...</Typography>
                            ) : (
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Third Party ID</TableCell>
                                            <TableCell>Expenses</TableCell>
                                            <TableCell>Income</TableCell>
                                            <TableCell>Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {Object.keys(uniqueThirdPartyIds).map((thirdPartyId, index) => (
                                            <TableRow key={index}>
                                                <TableCell>{uniqueThirdPartyIds[thirdPartyId].thirdPartyId == null ? 'N/A' : String(uniqueThirdPartyIds[thirdPartyId].thirdPartyId)}</TableCell>
                                                <TableCell>{uniqueThirdPartyIds[thirdPartyId].expenses}</TableCell>
                                                <TableCell>{uniqueThirdPartyIds[thirdPartyId].income}</TableCell>
                                                <TableCell>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={() => handleOpenModal(uniqueThirdPartyIds[thirdPartyId].thirdPartyId)}
                                                    >
                                                        View Details
                                                    </Button>
                                                    <Button
                                                        variant="contained"
                                                        color="secondary"
                                                        onClick={() => handleIgnoreThirdParty(uniqueThirdPartyIds[thirdPartyId].thirdPartyId)}
                                                        style={{ marginLeft: '10px' }}
                                                    >
                                                        Ignore
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>

                                </Table>
                            )}
                        </CardContent>
                    </Card>

                    <Modal
                        open={openModal}
                        onClose={handleCloseModal}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Paper style={{ padding: '20px', margin: '20px auto', maxWidth: '80%', maxHeight: '90vh', overflow: 'auto' }}>
                            <Typography variant="h6" mb={2}>
                                Transactions for {selectedThirdPartyId}
                            </Typography>
                            <Box mb={2}>
                                <FormControl fullWidth margin="normal">
                                    <InputLabel>Type de Tiers</InputLabel>
                                    <Select
                                        value={selectedParentTypes[selectedThirdPartyId] || ''}
                                        onChange={(e) => handleParentTypeChange(selectedThirdPartyId, e.target.value)}
                                    >
                                        <MenuItem key="client" value="client">
                                            Client
                                        </MenuItem>
                                        <MenuItem key="fournisseur" value="fournisseur">
                                            Fournisseur
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                                {selectedParentTypes[selectedThirdPartyId] && (
                                    <Box display="flex" alignItems="center" mt={2}>
                                        <FormControl fullWidth margin="normal">
                                            <InputLabel>Tierce Partie Associée</InputLabel>
                                            <Select
                                                value={selectedContainers[selectedThirdPartyId] || ''}
                                                onChange={(e) => handleContainerChange(selectedThirdPartyId, e.target.value)}
                                            >
                                                {(parentContainers[selectedThirdPartyId] || []).map((container) => (
                                                    <MenuItem key={container.id.value} value={container.id.value}>
                                                        {container.to_string?.value || container.id.value}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={handleCreateNewContainer}
                                            style={{ marginLeft: '10px' }}
                                        >
                                            New
                                        </Button>
                                    </Box>
                                )}
                            </Box>

                            <Button 
                                onClick={handleSave} 
                                variant="contained" 
                                color="primary" 
                                style={{ marginTop: '20px' }}
                            >
                                Save
                            </Button>

                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>ID</TableCell>
                                        <TableCell>Amount</TableCell>
                                        <TableCell>Date</TableCell>
                                        <TableCell>Description</TableCell>
                                        <TableCell>Currency Code</TableCell>
                                        <TableCell>Raw Source Data</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {filteredTransactions.map((transaction) => (
                                        <TableRow key={transaction.id.value}>
                                            <TableCell>{transaction.id.value}</TableCell>
                                            <TableCell>{transaction.amount.value.amount}</TableCell>
                                            <TableCell>{new Date(transaction.transaction_date.value).toLocaleDateString()}</TableCell>
                                            <TableCell>{transaction.unique_id.value}</TableCell>
                                            <TableCell>{transaction.currency_code.value}</TableCell>
                                            <TableCell>{JSON.stringify(transaction.raw_source_data.value)}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            <Button onClick={handleCloseModal} variant="contained" color="secondary" style={{ marginTop: '20px', marginLeft: '10px' }}>
                                Close
                            </Button>
                        </Paper>
                    </Modal>

                    <Modal
                                open={isMatchingRulesModalOpen}
                                onClose={handleCloseMatchingRulesModal}
                                aria-labelledby="matching-rules-modal-title"
                                aria-describedby="matching-rules-modal-description"
                            >
                                <Paper style={{ padding: '20px', margin: '20px auto', maxWidth: '80%', maxHeight: '90vh', overflow: 'auto' }}>
                                    <Typography variant="h6" mb={2}>
                                        Matching Rules
                                    </Typography>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Rule Type</TableCell>
                                                <TableCell>Third Party ID</TableCell>
                                                <TableCell>Parent Type</TableCell>
                                                <TableCell>Container ID</TableCell>
                                                <TableCell>Action</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {matchingRules.map((rule) => (
                                                <TableRow key={rule.id}>
                                                    <TableCell>{rule.rule_type}</TableCell>
                                                    <TableCell>{rule.third_party_id}</TableCell>
                                                    <TableCell>{rule.parent_type}</TableCell>
                                                    <TableCell>{rule.container_id}</TableCell>
                                                    <TableCell>
                                                        <Button
                                                            variant="contained"
                                                            color="error"
                                                            onClick={() => handleDeleteRule(rule.id)}
                                                        >
                                                            Delete
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                    <Button onClick={handleCloseMatchingRulesModal} variant="contained" color="secondary" style={{ marginTop: '20px' }}>
                                        Close
                                    </Button>
                                </Paper>
                            </Modal>

                    {currentCrmType && (
                        <CreateEditContainerInstance
                            isOpen={isCreateEditModalOpen}
                            onClose={() => setIsCreateEditModalOpen(false)}
                            crmType={currentCrmType}
                            onSave={handleSaveNewContainer}
                            parentDefinitions={parentTypes}
                            parentContainers={parentContainers}
                        />
                    )}
                </Box>
            </Box>
        </Dashboard>
    );
};

export default TransactionMatching;
