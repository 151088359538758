import React, { useState, useEffect, useContext } from 'react';
import { HttpRequestContext } from './HttpRequestProvider';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import { TextInputWithIcon } from './InputFields';

const DocumentEmailSender = ({ documentContainerId, documentType }) => {
    const { sendRequestWithToast } = useContext(HttpRequestContext);
    const [documentDetails, setDocumentDetails] = useState(null);
    const [clientEmail, setClientEmail] = useState('');
    const [senderEmail, setSenderEmail] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [templates, setTemplates] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState('');
    const [placeholders, setPlaceholders] = useState([]);
    const [templateFields, setTemplateFields] = useState({});

    useEffect(() => {
        const fetchDocumentDetails = async () => {
            try {
                const data = await sendRequestWithToast({
                    apiPath: `document/${documentContainerId}`,
                    type: 'GET',
                });
                if (data.success) {
                    setDocumentDetails(data.document);
                    setClientEmail(data.document.client.email || ''); 
                    setSenderEmail(data.document.billing_profile.email || '');
                } else {
                    setError(data.message || 'Erreur lors de la récupération des détails du document');
                }
            } catch (err) {
                setError(err.message || 'Une erreur est survenue.');
            } finally {
                setLoading(false);
            }
        };

        const fetchTemplates = async () => {
            try {
                const data = await sendRequestWithToast({
                    apiPath: '/emailtemplate/user',
                    type: 'GET',
                });
                if (data.success) {
                    setTemplates(data.templates);
                } else {
                    setError(data.message || 'Erreur lors de la récupération des templates');
                }
            } catch (err) {
                setError(err.message || 'Une erreur est survenue.');
            }
        };

        fetchDocumentDetails();
        fetchTemplates();
    }, [documentContainerId, sendRequestWithToast]);

    const handleTemplateChange = async (e) => {
        const templateId = e.target.value;
        setSelectedTemplate(templateId);

        try {
            const placeholdersData = await sendRequestWithToast({
                apiPath: `/emailtemplate/${templateId}/placeholders`,
                type: 'GET',
            });
            if (placeholdersData.success) {
                setPlaceholders(placeholdersData.placeholders);
            } else {
                setError(placeholdersData.message || 'Erreur lors de la récupération des placeholders');
            }

            const fieldsData = await sendRequestWithToast({
                apiPath: `/emailtemplate/${templateId}/fields`,
                type: 'GET',
            });
            if (fieldsData.success) {
                setTemplateFields(fieldsData.fields);
            } else {
                setError(fieldsData.message || 'Erreur lors de la récupération des champs');
            }
        } catch (err) {
            setError(err.message || 'Une erreur est survenue.');
        }
    };

    const handleFieldChange = (fieldName, value) => {
        setTemplateFields((prevFields) => ({
            ...prevFields,
            [fieldName]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        const emailData = {
            template_id: selectedTemplate,
            document_id: documentContainerId,
            fields: templateFields,
        };
    
        try {
            const response = await sendRequestWithToast({
                apiPath: `/send_oneshot_commercial_document_email`, 
                type: 'POST',
                request: emailData, 
                successMessage: "Document envoyé avec succès!",
                errorMessage: "Erreur lors de l'envoi du document",
            });
    
            if (response.success) {
                console.log('Email envoyé avec succès');
            } else {
                console.error('Erreur lors de l\'envoi de l\'email', response.message);
            }
        } catch (error) {
            console.error('Erreur lors de l\'envoi de l\'email', error);
        }
    };
    
    return (
        <Card variant="outlined">
            <CardContent>
                <Typography variant="h5" className="mb-5">
                    {`Envoyer le Document ${documentDetails?.details?.num_document || ''} par Email`}
                </Typography>
                {loading ? (
                    <div>Chargement des détails du document...</div>
                ) : error ? (
                    <div>Erreur: {error}</div>
                ) : (
                    <form onSubmit={handleSubmit}>
                        <FormControl fullWidth variant="outlined" margin="normal">
                            <InputLabel id="template-select-label">Sélectionner un Template</InputLabel>
                            <Select
                                labelId="template-select-label"
                                id="template-select"
                                value={selectedTemplate}
                                onChange={handleTemplateChange}
                                label="Sélectionner un Template"
                            >
                                {templates.map((template) => (
                                    <MenuItem key={template.id} value={template.id}>
                                        {template.display_name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        {placeholders.length > 0 && (
                            <div>
                                <Typography variant="subtitle1">Placeholders disponibles :</Typography>
                                <ul>
                                    {placeholders.map((placeholder, index) => (
                                        <li key={index}>{placeholder}</li>
                                    ))}
                                </ul>
                            </div>
                        )}

                        {Object.keys(templateFields).map((fieldName) => (
                            <TextField
                                key={fieldName}
                                label={fieldName}
                                value={templateFields[fieldName]}
                                onChange={(e) => handleFieldChange(fieldName, e.target.value)}
                                fullWidth
                                margin="normal"
                                variant="outlined"
                                multiline={fieldName.includes('contenu')}
                            />
                        ))}

                        <TextInputWithIcon
                            iconName="email"
                            label="Email du Client"
                            value={clientEmail}
                            onChange={(e) => setClientEmail(e.target.value)}
                            required
                        />
                        <TextInputWithIcon
                            iconName="email"
                            label="Votre Email"
                            value={senderEmail}
                            onChange={(e) => setSenderEmail(e.target.value)}
                            required
                        />
                        <Button
                            color='primary'
                            variant="contained"
                            ripple="true"
                            className='w-full md:w-auto'
                            type="submit"
                        >
                            Envoyer
                        </Button>
                    </form>
                )}
            </CardContent>
        </Card>
    );
};

export default DocumentEmailSender;
