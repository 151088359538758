import React, { useState } from 'react';
import AdminNavbar from '../Components/Admin/AdminNavbar';
import AdminSidebar from '../Components/Admin/AdminSidebar';
import { Outlet, useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';

const AdminLayout = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const navigate = useNavigate();

    const handleSectionChange = (route) => {
        navigate(route);
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <AdminNavbar />
            <AdminSidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} handleSectionChange={handleSectionChange} />
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    p: 3,
                    transition: 'margin-left 0.3s',
                    marginLeft: sidebarOpen ? '240px' : '60px',
                    paddingTop: '64px'
                }}
            >
                <Outlet />
            </Box>
        </Box>
    );
};

export default AdminLayout;
