import React, { useEffect, useState, useContext } from 'react';
import Dashboard from '../Layouts/Dashboard';
import PhoneInput from 'react-phone-input-2';
import { BookingCard } from './Modules/BookingCard';
import { UserContext } from '../Components/UserProvider';
import 'react-phone-input-2/lib/style.css';
import KanbanBoard from '../Components/Kanban';
import { SortableTable } from '../Components/Table';

const HomePage = () => {
    const { user } = useContext(UserContext);
    return (
        <Dashboard dashboardTitle={"Dashboard"}>
            <div className="p-4 w-full">
                <h1 className="text-2xl font-bold mb-4">Kanban Board</h1>
                <KanbanBoard />
            </div>
            <BookingCard /> 
        </Dashboard>
    );
};

export default HomePage;
