import React, { useState, useContext } from 'react';
import { Box, Card, CardContent, Typography, Button, TextField, List, ListItem, ListItemText } from '@mui/material';
import Dashboard from '../../Layouts/Dashboard';
import { HttpRequestContext } from '../../Components/HttpRequestProvider';

const CreateAppointment = () => {
    const { sendRequestWithToast } = useContext(HttpRequestContext);
    const [appointments, setAppointments] = useState([]);
    const [appointmentData, setAppointmentData] = useState({ start_datetime: '', end_datetime: '', description: '' });

    const handleCreateAppointment = async () => {
        const result = await sendRequestWithToast({
            apiPath: 'appointments/create',
            type: 'POST',
            request: appointmentData,
            successMessage: "Rendez-vous créé avec succès!",
            errorMessage: "Erreur lors de la création du rendez-vous",
        });

        if (result && result.success) {
            setAppointments([...appointments, appointmentData]);
            setAppointmentData({ start_datetime: '', end_datetime: '', description: '' });
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setAppointmentData((prevData) => ({ ...prevData, [name]: value }));
    };

    return (
        <Dashboard dashboardTitle="Créer un Rendez-vous">
            <Box p={3}>
                <Card>
                    <CardContent>
                        <Typography variant="h5" mb={2}>Créer un Rendez-vous</Typography>
                        <Box display="flex" alignItems="center" mb={2}>
                            <TextField
                                label="Début"
                                type="datetime-local"
                                name="start_datetime"
                                value={appointmentData.start_datetime}
                                onChange={handleChange}
                                InputLabelProps={{ shrink: true }}
                                sx={{ marginRight: 2, width: 'auto' }}
                            />
                            <TextField
                                label="Fin"
                                type="datetime-local"
                                name="end_datetime"
                                value={appointmentData.end_datetime}
                                onChange={handleChange}
                                InputLabelProps={{ shrink: true }}
                                sx={{ marginRight: 2, width: 'auto' }}
                            />
                            <TextField
                                label="Description"
                                name="description"
                                value={appointmentData.description}
                                onChange={handleChange}
                                sx={{ marginRight: 2, width: 'auto' }}
                            />
                            <Button variant="contained" color="primary" onClick={handleCreateAppointment}>
                                Créer
                            </Button>
                        </Box>
                        <Typography variant="h6" mt={3}>Rendez-vous</Typography>
                        <List>
                            {appointments.map((appointment, index) => (
                                <ListItem key={index}>
                                    <ListItemText primary={`Rendez-vous du ${appointment.start_datetime.replace('T', ' à ')} au ${appointment.end_datetime.replace('T', ' à ')}`} secondary={appointment.description} />
                                </ListItem>
                            ))}
                        </List>
                    </CardContent>
                </Card>
            </Box>
        </Dashboard>
    );
};

export default CreateAppointment;
