import React, { useState, useEffect, useContext } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { HttpRequestContext } from '../../Components/HttpRequestProvider';
import { SortableTable } from '../../Components/Table';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Modal from '../../Components/Modal';
import DocumentEmailSender from '../../Components/DocumentEmailSender';
import OverdueDocuments from './OverdueDocuments';

const CommercialDocumentTable = ({ apiType, entityName }) => {
    const { sendRequest } = useContext(HttpRequestContext);
    const [crmType, setCrmType] = useState(null);
    const [columns, setColumns] = useState({});
    const [error, setError] = useState(null);
    const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
    const [selectedDocumentId, setSelectedDocumentId] = useState(null);
    const [hasOverdueDocuments, setHasOverdueDocuments] = useState(false);
    const [showOverdueTable, setShowOverdueTable] = useState(false);
    const [overdueDocumentType, setOverdueDocumentType] = useState(apiType);
    const [documentMessage, setDocumentMessage] = useState('');

    useEffect(() => {
        const fetchCrmType = async () => {
            try {
                const responseData = await sendRequest({
                    apiPath: `crm/types/read/${apiType}`,
                    type: 'GET',
                });
                setCrmType(responseData);
                setColumns({
                    select: {
                        Type: 'checkbox',
                        name: 'Sélectionner',
                        sortable: false
                    },
                    num_document: {
                        name: 'Numéro de document',
                        Type: 'text',
                        sortable: true
                    },
                    date_emission: {
                        name: 'Date d\'émission',
                        Type: 'date',
                        sortable: true
                    },
                    "Client/Nom du Client": {
                        name: 'Nom du client',
                        Type: 'text',
                        sortable: true
                    },
                    "PdfContainer/Chemin du PDF": {
                        name: 'Chemin du PDF',
                        Type: 'image',
                        sortable: false
                    },
                    action: {
                        Type: 'action',
                        name: 'Actions',
                        sortable: false,
                        actionCallback: handleSendEmail,
                        tooltipContent: 'Envoyer Email',
                        actionType: 'email'
                    }
                });
            } catch (err) {
                setError(err.message);
            }
        };

        fetchCrmType();
    }, [apiType, sendRequest]);

    useEffect(() => {
        const checkForOverdueDocuments = async () => {
            try {
                if (apiType !== 'notedecredit') {
                    const overdueResponse = await sendRequest({
                        apiPath: `/overdue_documents/${apiType}/user`,
                        type: 'GET',
                    });

                    if (overdueResponse.rows.length > 0) {
                        setHasOverdueDocuments(true);
                        setDocumentMessage(`Il y a des ${apiType === 'facture' ? 'factures' : 'reçus'} en retard!`);
                    } else {
                        setHasOverdueDocuments(false);
                        setDocumentMessage(`Aucun ${apiType === 'facture' ? 'facture' : 'reçu'} en retard.`);
                    }
                } else {
                    setHasOverdueDocuments(false);
                    setDocumentMessage('');
                }
            } catch (err) {
                console.error('Error checking for overdue documents:', err);
            }
        };

        checkForOverdueDocuments();
    }, [sendRequest, apiType]);

    const handleSendEmail = (row) => {
        setSelectedDocumentId(row.id);
        setIsEmailModalOpen(true);
    };

    const closeEmailModal = () => {
        setIsEmailModalOpen(false);
        setSelectedDocumentId(null);
    };

    if (error) {
        return <p>Error: {error}</p>;
    }

    if (!crmType) {
        return <p>Loading...</p>;
    }

    const handleDownload = async (selectedRows) => {
        try {
            const response = await sendRequest({
                apiPath: '/documents/zip',
                type: 'POST',
                request: { documentIds: selectedRows },
                responseType: 'blob',
            });

            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'documents.zip');
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            console.error('Erreur lors du téléchargement des documents:', error);
        }
    };

    const handleShowOverdueTable = () => {
        setShowOverdueTable(true);
        setOverdueDocumentType(apiType);
    };

    const handleReturnToMainTable = () => {
        setShowOverdueTable(false);
    };

    return (
        <div>
            {apiType !== 'notedecredit' && (
                <Box mb={2} p={2} border={1} borderColor={hasOverdueDocuments ? "red" : "green"} bgcolor={hasOverdueDocuments ? "rgba(255, 0, 0, 0.1)" : "rgba(0, 255, 0, 0.1)"}>
                    <Typography variant="h6" color={hasOverdueDocuments ? "red" : "green"}>
                        {documentMessage}
                    </Typography>
                    {hasOverdueDocuments && (
                        <Button variant="contained" color="primary" onClick={handleShowOverdueTable}>
                            Voir les {apiType === 'facture' ? 'factures' : 'reçus'} en retard
                        </Button>
                    )}
                </Box>
            )}
            {showOverdueTable ? (
                <>
                    <Box mb={2}>
                        <Button variant="contained" color="primary" onClick={handleReturnToMainTable}>
                            Retour
                        </Button>
                    </Box>
                    <OverdueDocuments documentType={overdueDocumentType} />
                </>
            ) : (
                <Card variant="outlined" className="mb-4">
                    <CardContent>
                        <div className="flex justify-between items-center mb=2">
                            <Typography variant='h5' color='textPrimary'>
                                Liste: {crmType.display_name}
                            </Typography>
                        </div>
                        <SortableTable
                            apiEndpoint={`/crm/instances/${apiType}/list`}
                            Columns={columns}
                            selectionKey="id"
                            onMassAction={{
                                action: handleDownload,
                                buttonText: "Télécharger"
                            }}
                            updateApiEndpoint={`/documents/update/status`}
                            updatePropertyKey="status"
                        />
                    </CardContent>

                    <Modal isOpen={isEmailModalOpen} closeModal={closeEmailModal}>
                        <DocumentEmailSender documentContainerId={selectedDocumentId} documentType={apiType} />
                    </Modal>
                </Card>
            )}
        </div>
    );
};

export default CommercialDocumentTable;
