import React, { useState, useContext, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { HttpRequestContext, getBaseDomain } from '../../Components/HttpRequestProvider';
import { SortableTable } from '../../Components/Table';
import Modal from '../../Components/Modal';
import PDFViewerObject from '../../Components/PDFViewerObject';
import DocumentEmailSender from '../../Components/DocumentEmailSender';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

const OverdueDocuments = ({ documentType }) => {
    const { sendRequest } = useContext(HttpRequestContext);
    const [crmType, setCrmType] = useState(null);
    const [isPDFModalOpen, setPDFModalOpen] = useState(false);
    const [pdfFilePath, setPDFFilePath] = useState('');
    const [selectedDocumentId, setSelectedDocumentId] = useState(null);
    const [statusOptions, setStatusOptions] = useState([]);
    const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);

    useEffect(() => {
        const fetchStatusOptions = async () => {
            if (!documentType) return;

            try {
                const response = await sendRequest({
                    apiPath: `/crm/types/read/${documentType}`,
                    type: 'GET',
                });
                const statusProperty = response.properties.status;
                const options = statusProperty.accepted_values.map(value => ({ label: value, value }));
                setStatusOptions(options);
                setCrmType(response);
            } catch (error) {
                console.error("Erreur lors de la récupération des options de statut:", error);
                setStatusOptions([]);
            }
        };

        fetchStatusOptions();
    }, [sendRequest, documentType]);

    const handleOpenPDFModal = (pdfPath, documentId) => {
        const baseDomain = getBaseDomain();
        const pdfUrl = `//${baseDomain}/${pdfPath}`;
        setPDFFilePath(pdfUrl);
        setPDFModalOpen(true);
        setSelectedDocumentId(documentId);
    };

    const handleClosePDFModal = () => {
        setPDFModalOpen(false);
    };

    const handleSendEmail = (row) => {
        setSelectedDocumentId(row.id);
        setIsEmailModalOpen(true);
    };

    const closeEmailModal = () => {
        setIsEmailModalOpen(false);
        setSelectedDocumentId(null);
    };

    const columns = {
        select: {
            Type: 'checkbox',
            name: 'Sélectionner',
            sortable: false,
        },
        ...Object.fromEntries(Object.entries(crmType?.properties || {}).map(([key, property]) => {
            return [key, {
                name: property.display_name,
                Type: property.type === 'label' ? 'label' : 'text',
                sortable: true,
                options: property.type === 'label' ? statusOptions : undefined,
            }];
        })),
        action: {
            Type: 'action',
            name: 'Actions',
            sortable: false,
            actionCallback: handleSendEmail,
            tooltipContent: 'Envoyer Email',
            actionType: 'email',
        },
    };

    const renderTable = () => {
        if (!documentType || !crmType) return null;

        const apiEndpoint = `/overdue_documents/${documentType}/user`;

        return (
            <SortableTable
                apiEndpoint={apiEndpoint}
                Columns={columns}
                selectionKey="id"
                itemsPerPage={10}
                initialSortColumn="properties.date_echeance.value"
                initialSortDirection="asc"
                updateApiEndpoint={`/documents/update/status`}
                updatePropertyKey="status"
            />
        );
    };

    return (
        <div>
            <div className="table-container">
                {renderTable()}
            </div>

            <Modal isOpen={isPDFModalOpen} closeModal={handleClosePDFModal}>
                <Card className='bg-gray-200 w-full'>
                    <CardContent>
                        <Typography variant='h5' color='blue-gray'>
                            Visualisation du PDF
                        </Typography>
                        <PDFViewerObject filePath={pdfFilePath} />
                    </CardContent>
                </Card>
            </Modal>

            <Modal isOpen={isEmailModalOpen} closeModal={closeEmailModal}>
                <DocumentEmailSender documentContainerId={selectedDocumentId} documentType={documentType} />
            </Modal>
        </div>
    );
};

export default OverdueDocuments;
