import React, { useState, useContext } from 'react';
import { HttpRequestContext } from '../../Components/HttpRequestProvider';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import { TextInputWithIcon, TagInputWithIcon } from '../../Components/InputFields';

const CreateEmailTemplate = () => {
    const { sendRequestWithToast } = useContext(HttpRequestContext);
    const [postmarkId, setPostmarkId] = useState('');
    const [fields, setFields] = useState({});
    const [displayName, setDisplayName] = useState('');
    const [fieldKey, setFieldKey] = useState('');
    const [fieldValue, setFieldValue] = useState('');
    const [placeholders, setPlaceholders] = useState([]);

    const handleAddField = () => {
        setFields((prevFields) => ({
            ...prevFields,
            [fieldKey]: fieldValue,
        }));
        setFieldKey('');
        setFieldValue('');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const newTemplate = {
            postmark_id: postmarkId,
            fields: fields,
            display_name: displayName,
            placeholders: placeholders,
        };

        try {
            const response = await sendRequestWithToast({
                apiPath: '/emailtemplate/create',
                type: 'POST',
                request: newTemplate,
                successMessage: "Template créé avec succès!",
                errorMessage: "Erreur lors de la création du template",
            });

            if (response.success) {
                console.log('Template créé avec succès');
            } else {
                console.error('Erreur lors de la création du template', response.message);
            }
        } catch (error) {
            console.error('Erreur lors de la création du template', error);
        }
    };

    return (
        <Card variant="outlined">
            <CardContent>
                <Typography variant="h5" className="mb-5">
                    Créer un nouveau Template d'Email
                </Typography>
                <form onSubmit={handleSubmit}>
                    <TextInputWithIcon
                        id="postmark-id"
                        type="text"
                        placeholder="Postmark ID"
                        label="Postmark ID"
                        iconName="email"
                        value={postmarkId}
                        onChange={(e) => setPostmarkId(e.target.value)}
                        required
                    />
                    <TextInputWithIcon
                        id="template-name"
                        type="text"
                        placeholder="Nom du Template"
                        label="Nom du Template"
                        iconName="text_fields"
                        value={displayName}
                        onChange={(e) => setDisplayName(e.target.value)}
                        required
                    />
                    <TagInputWithIcon
                        id="placeholders"
                        placeholder="Ajouter des placeholders (appuyez sur enter après chaque placeholders)"
                        label="Placeholders"
                        value={placeholders}
                        onChange={setPlaceholders}
                    />
                    <Box mt={2} display="flex" flexWrap="wrap">
                        {placeholders.map((ph, index) => (
                            <Chip
                                key={index}
                                label={ph}
                                onDelete={() => setPlaceholders(placeholders.filter((p) => p !== ph))}
                                style={{ margin: 2 }}
                            />
                        ))}
                    </Box>
                    <TextInputWithIcon
                        id="field-key"
                        type="text"
                        placeholder="Clé du Champ"
                        label="Clé du Champ"
                        iconName="key"
                        value={fieldKey}
                        onChange={(e) => setFieldKey(e.target.value)}
                    />
                    <TextInputWithIcon
                        id="field-value"
                        type="text"
                        placeholder="Valeur du Champ"
                        label="Valeur du Champ"
                        iconName="text_fields"
                        value={fieldValue}
                        onChange={(e) => setFieldValue(e.target.value)}
                    />
                    <Button
                        color='primary'
                        variant="contained"
                        ripple="true"
                        className='w-full md:w-auto'
                        onClick={handleAddField}
                    >
                        Ajouter le Champ
                    </Button>
                    <Button
                        color='primary'
                        variant="contained"
                        ripple="true"
                        className='w-full md:w-auto mt-5'
                        type="submit"
                    >
                        Créer le Template
                    </Button>
                </form>
                <Typography variant="h6" className="mt-5">
                    Champs ajoutés:
                </Typography>
                <ul>
                    {Object.entries(fields).map(([key, value], index) => (
                        <li key={index}>{`${key}: ${value}`}</li>
                    ))}
                </ul>
            </CardContent>
        </Card>
    );
};

export default CreateEmailTemplate;
