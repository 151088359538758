import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import Dashboard from '../../Layouts/Dashboard';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { HttpRequestContext } from '../../Components/HttpRequestProvider';
import { SortableTable } from '../../Components/Table';
import KanbanBoard from '../../Components/Kanban';
import CreateEditContainerInstance from '../../Components/CreateEditContainerInstance';
import GanttChart from '../../Components/GanttChart';

const CreateCRMInstances = () => {
    const { typeName } = useParams();
    const { sendRequest } = useContext(HttpRequestContext);

    const [crmType, setCrmType] = useState(null);
    const [error, setError] = useState(null);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedInstance, setSelectedInstance] = useState(null);

    const [selectedKanbanColumn, setSelectedKanbanColumn] = useState('');

    useEffect(() => {
        const fetchCrmType = async () => {
            try {
                const responseData = await sendRequest({
                    apiPath: `crm/types/read/${typeName}`,
                    type: 'GET',
                });
                setCrmType(responseData);
            } catch (err) {
                setError(err.message);
            }
        };

        fetchCrmType();
    }, [typeName, sendRequest]);

    const handleNewInstance = () => {
        setSelectedInstance(null);
        setIsModalOpen(true);
    };

    const handleEditInstance = (instance) => {
        setSelectedInstance(instance);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setSelectedInstance(null);
    };

    const handleSave = () => {
        // You can add code here to refresh the table or Kanban board if needed
        setIsModalOpen(false);
    };

    if (error) {
        return <p>Error: {error}</p>;
    }

    if (!crmType) {
        return <p>Loading...</p>;
    }

    const labelProperties = crmType ? Object.entries(crmType.properties)
        .filter(([_, property]) => property.type === 'label')
        .map(([key, property]) => ({
            label: property.display_name,
            value: key
        })) : [];
    if (labelProperties.length > 0 && selectedKanbanColumn === '') {
        setSelectedKanbanColumn(labelProperties[0].value);
    }

    const forcedColumns = selectedKanbanColumn && crmType && crmType.properties[selectedKanbanColumn]
        ? crmType.properties[selectedKanbanColumn].accepted_values
        : [];

    const customColumns = {
        edit: {
            type: 'action',
            display_name: 'Modifier',
            name: 'edit',
            sortable: false,
            actionCallback: (row) => {
                console.log(row);
                handleEditInstance(row);
            },
            tooltipContent: "Modifier"
        }
    };

    return (
        <Dashboard dashboardTitle={'Créations d\'instances'}>

            <Card variant="outlined" className="mb-4">
                <CardContent>

                    <div className="flex justify-between items-center mb-2">
                        <Typography variant='h5' color='textPrimary'>
                            Liste: {crmType.display_name}
                        </Typography>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={handleNewInstance}
                        >
                            Nouveau
                        </Button>
                    </div>
                    <SortableTable
                        apiEndpoint={`/crm/instances/${typeName}/list`}
                        customColumns={customColumns}
                    />
                </CardContent>
            </Card>
            <Card variant="outlined" className="mb-4">
                <CardContent>
                    <div className="flex justify-between items-center mb-2">
                        <Typography variant='h5' color='textPrimary'>
                            Vue Kanban: {crmType.display_name}
                        </Typography>
                        <FormControl>
                            <InputLabel></InputLabel>
                            <Select
                                value={selectedKanbanColumn}
                                onChange={(e) => setSelectedKanbanColumn(e.target.value)}
                                displayEmpty
                                inputProps={{ 'aria-label': 'Without label' }}
                            >
                                {labelProperties.map((property) => (
                                    <MenuItem key={property.value} value={property.value}>
                                        {property.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <KanbanBoard
                        apiEndpoint={`/crm/instances/${typeName}/list`}
                        kanbanColumnField={selectedKanbanColumn}
                        forcedColumns={forcedColumns}
                    />
                </CardContent>
            </Card>
            {crmType && (
                <CreateEditContainerInstance
                    isOpen={isModalOpen}
                    onClose={handleCloseModal}
                    crmType={crmType}
                    selectedInstance={selectedInstance}
                    onSave={handleSave}
                />
            )}
        </Dashboard>
    );
};

export default CreateCRMInstances;