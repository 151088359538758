import React, { useContext, useState } from 'react';
import { PhoneInputWithIcon, TextInputWithIcon } from '../../Components/InputFields';
import { TextCard } from '../../Components/Cards';
import Button from '@mui/material/Button';
import { HttpRequestContext } from '../../Components/HttpRequestProvider';

export function BookingCard() {
    const [phone, setPhone] = useState('');
    const [name, setName] = useState('');
    const [companyName, setCompanyName] = useState('');
    const { sendRequestWithToast } = useContext(HttpRequestContext);

    const handleBooking = async () => {
        if (phone && name && companyName) {
            await sendRequestWithToast({
                apiPath: 'booking',
                request: { phone, name, companyName },
                type: 'POST',
                successMessage: 'Réservation effectuée avec succès!',
                errorMessage: 'Erreur lors de la réservation.'
            });
        } else {
            alert('Veuillez remplir tous les champs.');
        }
    };

    return (
        <TextCard
            icon="calendar"
            title="Rendez-vous de configuration"
            description="Découvrez et configurez nos fonctionnalités avec notre équipe. Réservez votre session maintenant pour une expérience optimale."
            imgSrc="https://images.unsplash.com/photo-1614624532983-4ce03382d63d"
            imgAlt="Nouvelle fonctionnalité"
            footer={
                <Button size="lg" fullWidth={true} onClick={handleBooking}>
                    Prendre un rendez-vous
                </Button>
            }
        >
            <div className="pt-6">
                <TextInputWithIcon
                    id="name"
                    placeholder="Jean Dupont"
                    label="Nom *"
                    iconName="person"
                    value={name}
                    onChange={e => setName(e.target.value)}
                />
                <TextInputWithIcon
                    id="companyName"
                    placeholder="Entreprise XYZ"
                    label="Nom de l'entreprise *"
                    iconName="business"
                    value={companyName}
                    onChange={e => setCompanyName(e.target.value)}
                />
                <PhoneInputWithIcon
                    id="phone"
                    placeholder="0412 34 56 78"
                    label="Numéro de téléphone *"
                    iconName="phone"
                    value={phone}
                    onChange={setPhone}
                />
            </div>
        </TextCard>
    );
}
