import React from 'react';
import image_login from '../Login.svg'; 
import { HorizontalSeparator } from '../Components/Separators';

export function BaseAuth({ children }) {
    return (
      <div>
        <div className="form-container">
            <div className="login-card overflow-hidden mb-10 sm:mb-0" style={{ maxWidth: '1000px' }}>
                <div className="md:flex w-full">
                    <img src={image_login} alt="Login Side Bar" className="hidden md:block w-1/2 bg-blue-100 py-10 px-10 border-r-2 border-cyan-800"/>
                    <div className="w-full md:w-1/2 py-10 px-5 md:px-10 flex flex-col" id="pageContent">
                        {children}
                    </div>
                </div>
            </div>
  
          <div className="fixed top-6 left-6 md:top-8 md:left-8 lg:top-10 lg:left-10">
            <a href="/" className="bg-cyan-800 hover:bg-cyan-700 text-white font-bold py-2 px-4 rounded-full">
              Retour à la page d'accueil
            </a>
          </div>
        </div>
      </div>
    );
  }

  export function HeaderWithSeparator({title}) {
    return (
        <div className="text-center mb-5">
            <a href="/"><img src="/assets/images/logo2.svg" alt="Logo" className="h-12 float-left" /></a>
            <h1 className="font-bold text-3xl text-gray-900">Innovaplus</h1>
            <p>{title}</p>
            <div className="mb-5 mt-5">
                <HorizontalSeparator />
            </div>
        </div>
    );
}

  export default BaseAuth;
  