import React, { useState } from 'react';
import { Button, Modal, Box, Typography } from '@mui/material';
import CreateEmailTemplate from '../CRM/CreateEmailTemplate';

const EmailingPage = () => {
    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <Box sx={{ p: 4 }}>
            <Typography variant="h4" gutterBottom>
                Emailing
            </Typography>
            <Button variant="contained" color="primary" onClick={handleOpen}>
                Créer un Template d'Email
            </Button>
            <Modal open={open} onClose={handleClose}>
                <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
                    <CreateEmailTemplate />
                </Box>
            </Modal>
        </Box>
    );
};

export default EmailingPage;
