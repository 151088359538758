import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { DropdownWithIcon, TextInputWithIcon } from '../../Components/InputFields';

const LineItemTable = ({ lineItems, onLineItemChange }) => {
    const handleAddItem = () => {
        const newItem = { description: '', quantity: 1, price: 0, tax: 0 };
        onLineItemChange([...lineItems, newItem]);
    };

    const handleItemChange = (index, field, value) => {
        const updatedItems = lineItems.map((item, i) => {
            if (i === index) {
                return { ...item, [field]: value };
            }
            return item;
        });
        onLineItemChange(updatedItems);
    };

    const handleRemoveItem = (index) => {
        const updatedItems = lineItems.filter((_, i) => i !== index);
        onLineItemChange(updatedItems);
    };

    return (
        <Card variant="level3">
            <CardContent>
                <Typography variant='h5' color='blue-gray'>
                    Produits et services
                </Typography>
                <table className="w-full">
                    <thead>
                        <tr>
                            <th>Description</th>
                            <th>Quantité</th>
                            <th>Prix HTVA</th>
                            <th>TVA (%)</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {lineItems.map((item, index) => (
                            <tr key={index}>
                                <td>
                                    <TextInputWithIcon
                                        iconName="pencil-outline"
                                        value={item.description}
                                        onChange={(e) => handleItemChange(index, 'description', e.target.value)}
                                        placeholder="Description"
                                        size="small"
                                    />
                                </td>
                                <td>
                                    <TextInputWithIcon
                                        type="number"
                                        iconName="cube-outline" 
                                        value={item.quantity.toString()}
                                        onChange={(e) => handleItemChange(index, 'quantity', e.target.value)}
                                        placeholder="Quantité"
                                        size="small"
                                    />
                                </td>
                                <td>
                                    <TextInputWithIcon
                                        type="number"
                                        iconName="tag-outline"
                                        value={item.price.toString()}
                                        onChange={(e) => handleItemChange(index, 'price', e.target.value)}
                                        placeholder="Prix Htva"
                                        size="small"
                                    />
                                </td>
                                <td>
                                    <TextInputWithIcon
                                        type="number"
                                        iconName="calculator" 
                                        value={item.tax.toString()}
                                        onChange={(e) => handleItemChange(index, 'tax', e.target.value)}
                                        placeholder="TVA (%)"
                                        size="small"
                                    />
                                </td>
                                <td>
                                    <IconButton aria-label="delete" color="error" onClick={() => handleRemoveItem(index)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <Button variant="contained" size="sm" color="secondary" ripple="true" onClick={handleAddItem}>Ajouter</Button>
            </CardContent>
        </Card>
    );
};

export default LineItemTable;