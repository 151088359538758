import React from 'react';
import { MDIIcon } from './Icons';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

export function TextCard({
    icon,
    title,
    description,
    imgSrc,
    imgAlt,
    children,
    footer,
    ...otherProps
}) {
    return (
        <Card sx={{ width: '100%', maxWidth: 416, boxShadow: 3 }} {...otherProps}>
            <CardHeader
                title={
                    <img src={imgSrc} alt={imgAlt} style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }} />
                }
                action={
                    <IconButton size="small" sx={{ position: 'absolute', top: 16, right: 16 }}>
                        <MDIIcon iconName={icon} additionalClasses="text-red-500" fontSize="large" />
                    </IconButton>
                }
                sx={{ position: 'relative', color: 'text.primary', backgroundColor: 'blueGrey' }}
            />
            <CardContent>
                <div style={{ marginBottom: 12, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="h5" component="div" sx={{ color: 'blueGrey' }}>
                        {title}
                    </Typography>
                </div>
                <Typography sx={{ color: 'text.secondary' }}>
                    {description}
                </Typography>
                {children}
            </CardContent>
            {footer && <CardActions>{footer}</CardActions>}
        </Card>
    );
}

export function LockedFeatureCard({
    imgSrc,
    imgAlt = 'Website Preview',
    siteTitle = 'Votre Site',
    overlayText = 'Website Preview Isn\'t Ready Yet',
    ...otherProps
}) {
    return (
        <Card sx={{ width: '100%', maxWidth: '36rem', maxHeight: '36rem', overflowY: 'auto', boxShadow: 3, position: 'relative' }} {...otherProps}>
            <CardHeader
                title={
                    <img src={imgSrc} alt={imgAlt} style={{ opacity: 0.5 }} />
                }
                action={
                    <IconButton size="small" sx={{ position: 'absolute', top: 16, right: 16 }}>
                        <MDIIcon iconName="lock" additionalClasses="text-red-500" fontSize="large" />
                    </IconButton>
                }
                sx={{ position: 'relative', color: 'text.primary', backgroundColor: 'blueGrey' }}
            />
            <div style={{ position: 'absolute', inset: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '4rem', padding: '1.5rem', background: 'linear-gradient(to top right, black, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7))' }}>
                <Typography variant="h5" component="div" sx={{ color: 'common.white', textAlign: 'center' }}>
                    {overlayText}
                </Typography>
            </div>
            <CardContent>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="h5" component="div" sx={{ color: 'blueGrey' }}>
                        {siteTitle}
                    </Typography>
                </div>
            </CardContent>
        </Card>
    );
}
