import React from 'react';
import Sidebar from '../../Components/Sidebar';
import { Email, People, Assessment, Settings } from '@mui/icons-material';

const AdminSidebar = ({ sidebarOpen, setSidebarOpen, handleSectionChange }) => {
    const categories = [
        { title: 'Emailing', icon: <Email />, route: '/admin/emailing' },
        { title: 'Utilisateurs', icon: <People />, route: '/admin/users' },
        { title: 'Rapports', icon: <Assessment />, route: '/admin/reports' },
        { title: 'Paramètres', icon: <Settings />, route: '/admin/settings' }
    ].map(section => ({
        title: section.title,
        icon: section.icon,
        key: section.route,
        route: section.route
    }));

    return (
        <Sidebar
            sidebarOpen={sidebarOpen}
            setSidebarOpen={setSidebarOpen}
            categories={categories}
            selectedLabel={null} // Assuming there is no profile label for admin
            onSelectChange={handleSectionChange}
            showProfileButton={false} // Set to false to hide the profile button
        />
    );
};

export default AdminSidebar;
