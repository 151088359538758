import React, { useState, useEffect, useContext } from 'react';
import { HttpRequestContext } from '../../Components/HttpRequestProvider';

function TemplateSelectionModal({closeModal,selectedTemplate, setSelectedTemplate , initial_search}) {
    const { sendRequest } = useContext(HttpRequestContext);
    const [query, setQuery] = useState(initial_search || "Marketing");
    const [templates, setTemplates] = useState([]);

    useEffect(() => {
        if (query) {
            sendRequest({
                apiPath:  `/website/search-template?q=${query}`,
                type: 'GET'
            })
            .then(response => setTemplates(response))
            .catch(error => console.error("Error fetching templates:", error));
        }
    }, [query, sendRequest]);

    const handleTemplateClick = (template) => {
        setSelectedTemplate(template);
    };    

    const handleValidateClick = () => {
        if (selectedTemplate) {
            closeModal(); 
        } else {
            alert('Veuillez sélectionner un template avant de valider.');
        }
    };

    const handleBackgroundClick = (event) => {
        // Check if the click was directly on the modal's background
        if (event.target.id === "templateModal") {
            handleValidateClick();
        }
    };

    return (
        <div id="templateModal" className={`modal fixed top-0 left-0 w-full h-full flex items-center justify-center`} onClick={handleBackgroundClick}>
            {/* Rest of your JSX content goes here, replacing jQuery functionality with React events and states */}
            <div className="modal-content bg-white w-full h-full sm:w-3/4 sm:h-auto sm:rounded-md shadow-lg relative overflow-hidden sm:items-start sm:mt-10 mt-25">
                {/* Fixed Header */}
                <div className="sticky top-0 z-10 bg-white py-4 px-4 shadow-md border-b border-gray-300">
                    <div className="flex items-center justify-between">
                        <input type="text" placeholder="Rechercher un template..." value={query} onChange={(e) => setQuery(e.target.value)} className="p-3 rounded-md shadow-lg border border-gray-300 flex-grow mr-4" />
                        <button className="btn-primary" onClick={handleValidateClick}>Valider</button>
                    </div>
                </div>

                <div className="container mx-auto px-4 py-8 sm:max-h-[calc(100vh-130px)]">
                    <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-6">
                        {templates.map(template => (
                            <div key={template.title} className={`template-item border rounded-md shadow-lg hover:shadow-xl transition-shadow duration-300 ${selectedTemplate === template ? 'selected' : ''}`} onClick={() => handleTemplateClick(template)}>
                                <img src={`/dist/img/templates/${template.image_file}`} alt={template.title} className="w-full rounded-t-md" />
                                <div className="p-4">
                                    <h2 className="text-lg font-bold">{template.title}</h2>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TemplateSelectionModal;
