import React from 'react';
import { MDIIcon } from './Icons';

export function InformationTextPanel({ text }) {
    return (
      <div className="relative p-4 rounded-md border border-gray-300 bg-cyan-50">
        <div className="absolute top-1 right-1">
          <MDIIcon iconName={"help-circle-outline"}/>
        </div>
        <p className="text-gray-700">
            {text}
        </p>
      </div>
    );
}
