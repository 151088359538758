import React, { useState, useContext } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { TextInputWithIcon } from '../../Components/InputFields';
import { HttpRequestContext } from '../../Components/HttpRequestProvider';
import Button from '@mui/material/Button';

const CreateCRMTypeForm = () => {
    const [description, setDescription] = useState('');
    const [displayName, setDisplayName] = useState('');

    const { sendRequestWithToast } = useContext(HttpRequestContext);

    const handleCreateType = async (e) => {
        e.preventDefault();

        if (!displayName) {
            alert("Please ensure the display name is valid.");
            return;
        }

        const newCRMTypeData = {
            description,     
            displayName
        };

        await sendRequestWithToast({
            apiPath: '/crm/types/create',
            type: 'POST',
            request: newCRMTypeData,
            successMessage: "The CRM type has been created successfully.",
            errorMessage: "Failed to create the CRM type."
        });
    };

    return (
        <Card variant="level1">
            <CardContent>
                <Typography variant='h5' color='blue-gray' className='mb-2'>
                    Création d'un nouveau type
                </Typography>
                <form onSubmit={handleCreateType}>
                    <TextInputWithIcon
                        name='displayName'
                        value={displayName}
                        onChange={(e) => setDisplayName(e.target.value)}
                        placeholder='Nom du type'
                        iconName='display-name-outline' 
                        label='Nom du type'
                    />
                    <TextInputWithIcon
                        name='description'
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        placeholder='Déscription'
                        iconName='description-outline' 
                        label='Description du type'
                        iconType='fa'
                    />

                    <Button
                        color='primary'
                        variant="contained"
                        type='submit'
                        ripple="true">
                        Procéder à la création
                    </Button>
                </form>
            </CardContent>
        </Card>
    );
};

export default CreateCRMTypeForm;
