import React, { useState, useContext } from 'react';
import { useForm } from "react-hook-form";
import { TextField, Button, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Select, MenuItem, InputLabel, Typography, Box } from '@mui/material';
import { HttpRequestContext, getBaseDomain } from '../../../Components/HttpRequestProvider';

import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
/* global gtag */


export default function FormPage() {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const theme = useTheme();
    const navigate = useNavigate(); // Hook for navigation
    const baseDomain = getBaseDomain();
    const [isSubmitted, setIsSubmitted] = useState(false);  // State to track if the form has been submitted

    const { sendRequestWithToast } = useContext(HttpRequestContext);


    const onSubmit = data => {

        sendRequestWithToast({
          apiPath: '/form',
          type: 'POST',
          request: data,
          successMessage: "Votre formulaire a été envoyé avec succès.",
          errorMessage: "Un problème est survenu."
      });

      setIsSubmitted(true);  // Set to true upon successful submission
      sendConversionToGTM();
    };

    const handleRedirect = (url) => {
      window.location.href = url;  // Directly change the browser's location
    };

    const sendConversionToGTM = () => {
      var callback = function () {
      };
  
      gtag('event', 'conversion', {
      'send_to':  "AW-862290376/9yOlCMK_yKUYEMiDlpsD",
      'event_callback': callback
      })
      console.log("Conversion has been sent");
    };


    if (isSubmitted) {

  

        return (
            <div className="flex items-center justify-center min-h-screen bg-gray-100">
                <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 text-center">
                    <Box className="flex items-center space-x-4 mb-8">
                      <img src="/assets/images/logo2.svg" alt="Logo Innovaplus" className="h-16 w-16" />
                      <h1 className="ml-3 font-bold text-3xl" style={{ color: theme.palette.primary.main }}>Innovaplus</h1>
                    </Box>

                    <Box className="mb-12">
                      <Typography variant="h5" component="h2" className="mb-4">
                          Merci pour votre soumission!
                      </Typography>
                      <Typography variant="body1" className="mb-4">
                          Nous reviendrons vers vous très bientôt.
                      </Typography>
                    </Box>

                    {/* Button to return to the homepage */}
                    <Button variant="contained" color="primary"   onClick={() => handleRedirect(`//${baseDomain}/`)}>
                        Retour à l'accueil
                    </Button>

                    {/* Button to go to the blog */}
                    <Button variant="outlined" color="primary" onClick={() => handleRedirect(`//${baseDomain}/articles`)} className="mt-4">
                        Visitez notre Blog
                    </Button>
                </div>
            </div>
        );
    }



    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-4">
        <form onSubmit={handleSubmit(onSubmit)} className="w-full max-w-lg bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">

        <Box className="flex items-center space-x-4 mb-8">
          <img src="/assets/images/logo2.svg" alt="Logo Innovaplus" className="h-16 w-16" />
          <h1 className="ml-3 font-bold text-3xl" style={{ color: theme.palette.primary.main }}>Innovaplus</h1>
        </Box>



          <div className="mb-4">
            <TextField
              fullWidth
              label="Votre Nom & Prénom"
              variant="outlined"
              {...register("clientName", { required: "Merci d'indiquer votre nom & prénom" })}
              error={!!errors.clientName}
              helperText={errors.clientName?.message}
            />
          </div>

          <div className="mb-4">
            <TextField
              fullWidth
              label="Nom de l'entreprise"
              variant="outlined"
              {...register("businessName", { required: "Le nom de l'entreprise est requis" })}
              error={!!errors.businessName}
              helperText={errors.businessName?.message}
            />
          </div>
          <div className="mb-4">
            <TextField
              fullWidth
              label="Numéro de TVA (optionnel)"
              variant="outlined"
              {...register("vatNumber")}
            />
          </div>

          <div className="mb-4">
            <TextField
              fullWidth
              label="Email de contact"
              variant="outlined"
              {...register("contactEmail", { required: "L'email est requis", pattern: { value: /^\S+@\S+$/i, message: "Format d'email invalide" } })}
              error={!!errors.contactEmail}
              helperText={errors.contactEmail?.message}
            />
          </div>

          <div className="mb-4">
            <TextField
              fullWidth
              label="Numéro de téléphone"
              variant="outlined"
              {...register("phone", { required: "Le numéro de téléphone est requis" })}
              error={!!errors.phone}
              helperText={errors.phone?.message}
            />
          </div>

          <div className="mb-4">
            <FormControl fullWidth error={!!errors.interest}>
              <InputLabel id="interest-label">Vous êtes intéressé par :</InputLabel>
              <Select
                labelId="interest-label"
                label="Vous êtes intéressé par"
                defaultValue=""
                {...register("interest", { required: "Veuillez sélectionner une option" })}
              >
                <MenuItem value="website">Création/Optimization de mon site web</MenuItem>
                <MenuItem value="marketing">Marqueting: Acquérir plus de clients</MenuItem>
                <MenuItem value="crm">CRM: Facturation & Suivit Client</MenuItem>
                <MenuItem value="multiple">Une solution sur mesure avec Site + Marqueting + CRM </MenuItem>
              </Select>
            </FormControl>
          </div>


          <div className="mb-4">
            <FormControl component="fieldset" error={!!errors.businessDuration}>
              <FormLabel component="legend">Depuis combien de temps avez-vous commencé votre activité?</FormLabel>
              <Select
                fullWidth
                {...register("businessDuration", { required: "Ce champ est requis" })}
              >
                <MenuItem value="notYet">Je n'ai encore commencé</MenuItem>
                <MenuItem value="0-6-months">0 à 6 mois</MenuItem>
                <MenuItem value="6-12-months">6 mois - 1 an</MenuItem>
                <MenuItem value="1-2-years">1 an - 2 ans</MenuItem>
                <MenuItem value="2-5-years">2 ans - 5 ans</MenuItem>
                <MenuItem value="5plus-years">plus de 5 ans</MenuItem>
              </Select>
            </FormControl>
          </div>


          <div className="mb-4">
            <FormControl component="fieldset" error={!!errors.currentWebsite}>
              <FormLabel component="legend">Avez-vous déjà un site web?</FormLabel>
              <RadioGroup row>
                <FormControlLabel
                  control={<Radio {...register("currentWebsite", { required: "Ce champ est requis" })} />}
                  label="Oui"
                  value="Yes"
                />
                <FormControlLabel
                  control={<Radio {...register("currentWebsite", { required: "Ce champ est requis" })} />}
                  label="Non"
                  value="No"
                />
              </RadioGroup>
            </FormControl>
          </div>
          
          
          <Button type="submit" fullWidth variant="contained" color="primary">
            Soumettre
          </Button>
        </form>
      </div>
    );
}
