import React from 'react';

export function CustomButton({ onClick, label, styleClass, blockClass,href }) {
    return (
      <div className="flex -mx-3">
        <div className="w-full px-3">
          <button onClick={onClick} className={`${styleClass} ${blockClass}`} href={href}>
            {label}
          </button>
        </div>
      </div>
    );
  }
  