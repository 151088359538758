import React, { useState, useContext, useRef } from 'react';
import BaseAuth , {HeaderWithSeparator} from '../Layouts/BaseAuth';
import ReCAPTCHA from "react-google-recaptcha";
import { HttpRequestContext } from '../Components/HttpRequestProvider';
import { CustomButton } from '../Components/Buttons';
import { TextInputWithIcon } from '../Components/InputFields';
import { HorizontalSeparator } from '../Components/Separators';
import { useNavigate } from 'react-router-dom';  // <-- Step 1: Import useNavigate


export function Login() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [recaptchaResponse, setRecaptchaResponse] = useState("");

    const recaptchaRef = useRef(null);
    const { sendRequestWithToast } = useContext(HttpRequestContext);

    const navigate = useNavigate();  // <-- Step 2: Get the navigate function


    const handleRecaptchaChange = (value) => {
        setRecaptchaResponse(value);
    }

    const processLogin = async () => {
        console.log("Attempting to log in...");
        const request = {
            email: email,
            password: password,
            recaptchaResponse: recaptchaResponse 
        };

        const response = await sendRequestWithToast({
            apiPath: "login",   // This is the main change, replacing 'url' with 'apiPath'
            request: request,
            type: "POST",
            successMessage: "Connection réussie",
            errorMessage: "Erreur lors de la connexion",
            //successRedirectUrl: "/booking",
            needRecaptcha: true,
            recaptchaRef: recaptchaRef
        });
        
        console.log(response);
        if (response && response.email) {
            navigate('/');  // <-- Step 3: Redirect to "/" route
        }
    }

    
    return (
        <BaseAuth>

            <div className="flex-grow">
                <HeaderWithSeparator title="Connection à la plateforme" />
                <div className="space-y-5">


                    <TextInputWithIcon
                        id="login-email"
                        type="email"
                        placeholder="johnsmith@example.com"
                        label="Email"
                        iconName="email-outline"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />

                    <TextInputWithIcon
                        id="login-pass"
                        type="password"
                        placeholder="************"
                        label="Mot de passe"
                        iconName="lock-outline"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    
                    <div className="flex -mx-3">
                        <div className="w-full px-3">
                            <a href="/recover-password" className="text-xs text-cyan-800 hover:underline mt-2 inline-block">Mot de passe oublié?</a>
                        </div>
                    </div>

                    <div className="flex -mx-3">
                        <div className="w-full px-3">
                        <ReCAPTCHA
                            ref={recaptchaRef}
                            sitekey="6Lcbsh4lAAAAAFUAQ1ZLvNzJM3UZHgigjw-LLGZg"
                            onChange={handleRecaptchaChange}
                        />
                        </div>
                    </div>

                    <CustomButton
                        onClick={processLogin}
                        label="SE CONNECTER"
                        styleClass="btn-primary"
                        blockClass="block w-full max-w-xs mx-auto"
                    />

                </div>
            </div>

        </BaseAuth>
    );
}